import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "service/api";
import endpoints from "../service/endpoints";
import { ManualDip } from "./actionTypes";

/**
 * Function get list ground water level
 */
export const getListManualDip = createAsyncThunk(
  `gwl/fieldsheet/${ManualDip.GET_LIST}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.manualDip.GET_MANUAL_DIP,
        method: "POST",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get list ground water level
 */
export const getListBoreholeID = createAsyncThunk(
  `gwl/fieldsheet/${ManualDip.GET_LIST_BOREHOLE_ID}`,
  async (projectNumberId: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.manualDip.LIST_BOREHOLE_ID + `/${projectNumberId}`,
        method: "GET",
        header: "content-type: application/json",
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get list ground water level
 */
export const getListProjectNumber = createAsyncThunk(
  `gwl/fieldsheet/${ManualDip.GET_LIST_PROJECT_NUMBER}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.manualDip.LIST_PROJECT_NUMBER,
        method: "GET",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get list ground water level
 */
export const getFieldSheetData = createAsyncThunk(
  `gwl/fieldsheet/${ManualDip.GET_FIELD_DATA_SHEET}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.manualDip.GET_FIELD_SHEET_DATA + `/${data.piezometerid}`,
        method: "POST",
        header: "content-type: application/json",
        data: data.data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get list ground water level
 */
export const getFieldSheetDataGraph = createAsyncThunk(
  `gwl/fieldsheet/${ManualDip.GET_FIELD_DATA_SHEET_GRAPH}`,
  async (piezometerid: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url:
          endpoints.manualDip.GET_FIELD_SHEET_DATA_GRAPH + `/${piezometerid}`,
        method: "GET",
        header: "content-type: application/json",
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get list ground water level
 */
export const getScreenCoreGS = createAsyncThunk(
  `gwl/fieldsheet/${ManualDip.GET_BOREHOLE_ID_CORE_GS}`,
  async (dataSeacrch: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url:
          endpoints.groundWaterLevel.GET_BOREHOLEID_CORE_GS +
          `/${dataSeacrch.projectNumber}/${dataSeacrch.boreholeID}`,
        method: "GET",
        header: "content-type: application/json",
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get list ground water level
 */
export const getProjectNumberCoreGS = createAsyncThunk(
  `gwl/fieldsheet/${ManualDip.GET_PROJECT_NUMBER_CORE_GS}`,
  async (projectNumber: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url:
          endpoints.groundWaterLevel.GET_PROJET_CORE_GS + `/${projectNumber}`,
        method: "GET",
        header: "content-type: application/json",
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get list ground water level
 */
export const getListMasterTable = createAsyncThunk(
  `gwl/fieldsheet/${ManualDip.GET_VISIT_DATA}`,
  async (piezometer_id: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.manualDip.GET_MASTER_TABLE + `/${piezometer_id}`,
        method: "GET",
        header: "content-type: application/json",
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get group reviewer user data
 */

export const getMasterTableReviewerData = createAsyncThunk(
  `gwl/auth/${ManualDip.GET_MASTER_TABLE_REVIEWER_DATA}`,
  async (piezometer_id: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url:
          endpoints.manualDip.GET_MASTER_TABLE_REVIEWER_DATA +
          `/${piezometer_id}`,
        method: "GET",
        header: "content-type: application/json",
      });

      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get group reviewer user data
 */

export const getGroupReviewerData = createAsyncThunk(
  `gwl/auth/${ManualDip.GROUP_REVIEWER_DATA}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.groundWaterLevel.GROUP_REVIEWER_DATA,
        method: "GET",
        header: "content-type: application/json",
      });

      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get list ground water level
 */
export const getListResultTable = createAsyncThunk(
  `gwl/resulttable/${ManualDip.GET_RESULT_TABLE_LIST}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.manualDip.GET_DATA_RESULT_DATA,
        method: "POST",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get list ground water level
 */
export const getDetailSummary = createAsyncThunk(
  `gwl/resulttable/${ManualDip.GET_DETAIL_SUMMARY}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.manualDip.GET_DETAIL_SUMMARY + `/${data}`,
        method: "GET",
        header: "content-type: application/json",
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get group reviewer user data
 */

export const getDataAttributes = createAsyncThunk(
  `gwl/mastertable/${ManualDip.GET_DATA_ATTRIBUTES}`,
  async (piezometer_id: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.manualDip.GET_DATA_ATTRIBUTES + `/${piezometer_id}`,
        method: "GET",
        header: "content-type: application/json",
      });

      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/** @type {object} init state of pagination */
export const pagination = {
  page: 1,
  pagesize: 10,
  // pagesize: 10,
  total: 0,
};

/** @type {object} init state of cocs */
const initialState = {
  projectsNumberList: [],
  loading: false,
  paginationManualDip: pagination,
  listManualDip: [],
  projectNumberSearch: undefined,
  boreHoleIDSearch: undefined,
  loadingBoreholeID: false,
  loadingSceen: false,
  screenSearch: undefined,
  siteVisitSearch: undefined,
  fileNameUpload: undefined,
  fileUpload: undefined,
  filePath: undefined,
  listProjectNumberCoreGS: [],
  projectNumberCoreGS: undefined,
  boreholeIDCoreGS: undefined,
  listScreenCoreGS: [],
  loadingScreenCoreGS: false,
  screenCoreGS: undefined,
  listBoreholeID: [],
  listFieldData: [],
  paginationFieldData: pagination,
  listFieldDataGraph: [],
  loadingFieldDataGraph: false,
  loadingFieldData: false,
  listMasterData: [],
  loadingMasterData: false,
  listGraphMasterData: [],
  listSWLGraphMasterData: [],
  loadingResultTable: false,
  paginationResultTable: pagination,
  listDataResultTable: [],
  projectNumberSearchResultTable: undefined,
  boreHoleIDSearchResultTable: undefined,
  startedFrom: undefined,
  startedTo: undefined,
  reponseUrlMasterData: undefined,
  groupReviewerData: undefined,
  masterTableReviewerData: undefined,
  loadingDetailSummary: false,
  dataDetailSummary: [],
  loadingAttributes: false,
  dataAttributes: undefined,
  isSearch: false,
  graphTitle: undefined,
  graphTitleMaster: undefined,
  error: "",
};

const manualDipData = createSlice({
  name: "coc",
  initialState,
  reducers: {
    setClearSearch: (state, action) => {
      state.projectNumberSearch = undefined;
      state.boreHoleIDSearch = undefined;
      state.screenSearch = undefined;
      state.siteVisitSearch = undefined;
      state.listBoreholeID = [];
      state.fileNameUpload = undefined;
      state.fileUpload = undefined;
      state.filePath = undefined;
      state.siteVisitSearch = undefined;
      state.listBoreholeID = [];
      state.projectNumberSearchResultTable = undefined;
      state.boreHoleIDSearchResultTable = undefined;
      state.startedFrom = undefined;
      state.startedTo = undefined;
      state.isSearch = false;
    },
    setClearFieldSearch: (state, action) => {
      state.projectNumberSearch = undefined;
      state.boreHoleIDSearch = undefined;
      state.screenSearch = undefined;
      state.siteVisitSearch = undefined;
      state.fileNameUpload = undefined;
      state.fileUpload = undefined;
      state.filePath = undefined;
      state.siteVisitSearch = undefined;
    },
    setClearAllFiledQuery: (state, action) => {
      state.projectNumberSearch = undefined;
      state.boreHoleIDSearch = undefined;
      state.screenSearch = undefined;
      state.fileNameUpload = undefined;
      state.fileUpload = undefined;
      state.filePath = undefined;
      state.siteVisitSearch = undefined;
      state.listBoreholeID = [];
      state.projectNumberSearchResultTable = undefined;
      state.boreHoleIDSearchResultTable = undefined;
      state.startedFrom = undefined;
      state.startedTo = undefined;
      state.paginationFieldData = pagination;
      state.paginationResultTable = pagination;
      state.isSearch = false;
    },
    setLoadingFieldData: (state, action) => {
      state.loadingFieldData = action.payload;
    },
    setProjectNumberSearch: (state, action) => {
      state.projectNumberSearch = action.payload;
      state.boreHoleIDSearch = undefined;
      state.screenSearch = undefined;
      state.siteVisitSearch = undefined;
    },
    setBoreHoleIDSearch: (state, action) => {
      state.boreHoleIDSearch = action.payload;
      state.screenSearch = undefined;
      state.siteVisitSearch = undefined;
    },
    setScreenSearch: (state, action) => {
      state.screenSearch = action.payload;
      state.siteVisitSearch = undefined;
    },
    setSiteVisitSearch: (state, action) => {
      state.siteVisitSearch = action.payload;
    },
    setClearAddScheckFieldGS: (state, action) => {
      state.projectNumberCoreGS = undefined;
      state.boreholeIDCoreGS = undefined;
      state.screenCoreGS = undefined;
    },
    setProjectNumberCoreGS: (state, action) => {
      state.projectNumberCoreGS = action.payload;
      state.boreholeIDCoreGS = undefined;
      state.screenCoreGS = undefined;
    },
    setBoreholeIDCoreGS: (state, action) => {
      state.boreholeIDCoreGS = action.payload;
      state.screenCoreGS = undefined;
    },
    setScreenCoreGS: (state, action) => {
      state.screenCoreGS = action.payload;
    },
    setLoadingMasterData: (state, action) => {
      state.loadingMasterData = action.payload;
    },
    setClearDataGraphMasterTables: (state, action) => {
      state.listGraphMasterData = action.payload;
      state.listSWLGraphMasterData = action.payload;
      state.listMasterData = action.payload;
      state.graphTitleMaster = action.payload;
    },
    setProjectNumberSearchResultTable: (state, action) => {
      state.projectNumberSearchResultTable = action.payload;
      state.boreHoleIDSearchResultTable = undefined;
    },
    setBoreHoleIDSearchResultTable: (state, action) => {
      state.boreHoleIDSearchResultTable = action.payload;
    },
    setRecordFrom: (state, action) => {
      state.startedFrom = action.payload;
    },
    setRecordTo: (state, action) => {
      state.startedTo = action.payload;
    },
    setLoadingResultTable: (state, action) => {
      state.loadingResultTable = action.payload;
    },
    setClearDetailSummary: (state, action) => {
      state.dataDetailSummary = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsSearch: (state, action) => {
      state.isSearch = action.payload;
    },
  },
  extraReducers: {
    [getListManualDip.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getListManualDip.rejected.toString()]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getListManualDip.fulfilled.toString()]: (state, action) => {
      state.listManualDip = action.payload.data.items;
      state.paginationManualDip = {
        page: action.payload?.page || 1,
        pagesize: action.payload?.pagesize || 10,
        total: action.payload?.total || 0,
      };
      state.error = "";
      state.loading = false;
    },
    [getListProjectNumber.rejected.toString()]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getListProjectNumber.fulfilled.toString()]: (state, action) => {
      state.projectsNumberList = action.payload.project_number_list;
      state.error = "";
    },
    [getListBoreholeID.pending.toString()]: (state) => {
      state.loadingBoreholeID = true;
    },
    [getListBoreholeID.rejected.toString()]: (state, action) => {
      state.error = action.payload;
      state.loadingBoreholeID = false;
    },
    [getListBoreholeID.fulfilled.toString()]: (state, action) => {
      state.listBoreholeID = action.payload.datas;
      state.error = "";
      state.loadingBoreholeID = false;
    },
    [getFieldSheetData.pending.toString()]: (state) => {
      state.loadingFieldData = true;
    },
    [getFieldSheetData.rejected.toString()]: (state, action) => {
      state.loadingFieldData = false;
      state.error = action.payload;
    },
    [getFieldSheetData.fulfilled.toString()]: (state, action) => {
      state.loadingFieldData = false;
      state.listFieldData = action.payload.data.items;
      state.paginationFieldData = {
        page: action.payload?.page || 1,
        pagesize: action.payload?.pagesize || 10,
        total: action.payload?.total || 0,
      };
      state.error = "";
    },
    [getFieldSheetDataGraph.pending.toString()]: (state) => {
      state.loadingFieldDataGraph = true;
    },
    [getFieldSheetDataGraph.rejected.toString()]: (state, action) => {
      state.loadingFieldDataGraph = false;
      state.error = action.payload;
    },
    [getFieldSheetDataGraph.fulfilled.toString()]: (state, action) => {
      state.loadingFieldDataGraph = false;
      state.listFieldDataGraph = action.payload.data;
      state.graphTitle = action.payload.graph_title;
      state.error = "";
    },
    [getScreenCoreGS.pending.toString()]: (state) => {
      state.loadingScreenCoreGS = true;
    },
    [getScreenCoreGS.rejected.toString()]: (state, action) => {
      state.loadingScreenCoreGS = false;
      state.error = action.payload;
    },
    [getScreenCoreGS.fulfilled.toString()]: (state, action) => {
      state.listScreenCoreGS = action.payload.records;
      state.loadingScreenCoreGS = false;
      state.error = "";
    },
    [getProjectNumberCoreGS.pending.toString()]: (state) => {},
    [getProjectNumberCoreGS.rejected.toString()]: (state, action) => {
      state.error = action.payload;
    },
    [getProjectNumberCoreGS.fulfilled.toString()]: (state, action) => {
      state.listProjectNumberCoreGS = action.payload.records;
      state.error = "";
    },
    [getListMasterTable.pending.toString()]: (state) => {
      state.loadingMasterData = true;
    },
    [getListMasterTable.rejected.toString()]: (state, action) => {
      state.loadingMasterData = false;
      state.error = action.payload;
    },
    [getListMasterTable.fulfilled.toString()]: (state, action) => {
      state.loadingMasterData = false;
      state.listMasterData = action.payload.data;
      state.listGraphMasterData =
        action.payload.graph && action.payload.graph !== null
          ? action.payload.graph
          : [];
      state.listSWLGraphMasterData =
        action.payload.graph && action.payload.swl_graph !== null
          ? action.payload.swl_graph
          : [];
      state.graphTitleMaster = action.payload.graph_title;
      state.error = "";
    },
    [getMasterTableReviewerData.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getMasterTableReviewerData.rejected.toString()]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getMasterTableReviewerData.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.masterTableReviewerData = action.payload.data;
      state.error = "";
    },
    [getGroupReviewerData.rejected.toString()]: (state, action) => {
      state.error = action.payload;
    },
    [getGroupReviewerData.fulfilled.toString()]: (state, action) => {
      state.groupReviewerData = action.payload;
      state.error = "";
    },
    [getListResultTable.pending.toString()]: (state) => {
      state.loadingResultTable = true;
    },
    [getListResultTable.rejected.toString()]: (state, action) => {
      state.loadingResultTable = false;
      state.error = action.payload;
    },
    [getListResultTable.fulfilled.toString()]: (state, action) => {
      state.loadingResultTable = false;
      state.listDataResultTable = action.payload.data.items;
      state.paginationResultTable = {
        page: action.payload?.page || 1,
        pagesize: action.payload?.pagesize || 10,
        total: action.payload?.total || 0,
      };
      state.error = "";
    },
    [getDetailSummary.pending.toString()]: (state) => {
      state.loadingDetailSummary = true;
    },
    [getDetailSummary.rejected.toString()]: (state, action) => {
      state.loadingDetailSummary = false;
      state.error = action.payload;
    },
    [getDetailSummary.fulfilled.toString()]: (state, action) => {
      state.loadingDetailSummary = false;
      state.dataDetailSummary = action.payload;
      state.error = "";
    },
    [getDataAttributes.pending.toString()]: (state) => {
      state.loadingAttributes = true;
    },
    [getDataAttributes.rejected.toString()]: (state, action) => {
      state.loadingAttributes = false;
      state.error = action.payload;
    },
    [getDataAttributes.fulfilled.toString()]: (state, action) => {
      state.loadingAttributes = false;
      state.dataAttributes = action.payload;
      state.error = "";
    },
  },
});

export const {
  setLoadingFieldData,
  setProjectNumberSearch,
  setBoreHoleIDSearch,
  setScreenSearch,
  setSiteVisitSearch,
  setClearSearch,
  setClearAllFiledQuery,
  setClearFieldSearch,
  setClearAddScheckFieldGS,
  setProjectNumberCoreGS,
  setBoreholeIDCoreGS,
  setScreenCoreGS,
  setLoadingMasterData,
  setClearDataGraphMasterTables,
  setProjectNumberSearchResultTable,
  setBoreHoleIDSearchResultTable,
  setRecordFrom,
  setRecordTo,
  setLoadingResultTable,
  setClearDetailSummary,
  setLoading,
  setIsSearch,
} = manualDipData.actions;

const { reducer: manualDip } = manualDipData;
export default manualDip;
