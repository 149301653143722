import axios from 'axios';
import { getToken } from 'helpers/webStorage';
import { API_SERVER_P2 } from '../constants/config';

const axios_p2 = axios.create({
  baseURL: API_SERVER_P2,
});
  
const addAuthToken = (axiosInstance) => {
  axiosInstance.interceptors.request.use(
    function (axios_config) {
      const token = getToken();
      if (axios_config.isAuth && token) {
        axios_config.headers.Authorization = 'Bearer ' + token;
      }
      return axios_config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
};

// Add interceptors to each instance
addAuthToken(axios_p2); 

export default axios_p2;

export const isCancel = (error) => {
  return axios.isCancel(error);
};
