import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "service/api";
import endpoints from "../service/endpoints";
import { GroundWaterLevel } from "./actionTypes";
import { toast } from "react-toastify";

/**
 * Function get list ground water level
 */
export const getListGroundWaterLevel = createAsyncThunk(
  `gwl/fieldsheet/${GroundWaterLevel.GET_LIST}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.groundWaterLevel.LIST,
        method: "POST",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get list ground water level
 */
export const getListProjectNumber = createAsyncThunk(
  `gwl/fieldsheet/${GroundWaterLevel.GET_LIST_PROJECT_NUMBER}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.groundWaterLevel.LIST_PROJECT_NUMBER,
        method: "GET",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get list ground water level
 */
export const getListBoreholeID = createAsyncThunk(
  `gwl/fieldsheet/${GroundWaterLevel.GET_LIST_BOREHOLE_ID}`,
  async (projectNumberId: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.groundWaterLevel.LIST_BOREHOLE_ID + `${projectNumberId}`,
        method: "GET",
        header: "content-type: application/json",
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get list ground water level
 */
export const getListLevelLogger = createAsyncThunk(
  `gwl/fieldsheet/${GroundWaterLevel.GET_LIST_LEVEL_LOGGER}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.groundWaterLevel.LIST_LEVEL_LOGGER,
        method: "POST",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get list ground water level
 */
export const uploadLevelLogger = createAsyncThunk(
  `gwl/fieldsheet/${GroundWaterLevel.UPLOAD_LEVEL_LOGGER}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.groundWaterLevel.UPLOAD_LEVEL_LOGGER,
        method: "POST",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get list ground water level
 */
export const getProjectNumberCoreGS = createAsyncThunk(
  `gwl/fieldsheet/${GroundWaterLevel.GET_PROJECT_NUMBER_CORE_GS}`,
  async (projectNumber: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url:
          endpoints.groundWaterLevel.GET_PROJET_CORE_GS + `/${projectNumber}`,
        method: "GET",
        header: "content-type: application/json",
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get list ground water level
 */
export const getScreenCoreGS = createAsyncThunk(
  `gwl/fieldsheet/${GroundWaterLevel.GET_BOREHOLE_ID_CORE_GS}`,
  async (dataSeacrch: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url:
          endpoints.groundWaterLevel.GET_BOREHOLEID_CORE_GS +
          `/${dataSeacrch.projectNumber}/${dataSeacrch.boreholeID}`,
        method: "GET",
        header: "content-type: application/json",
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get list ground water level
 */
export const createScheduleFieldGS = createAsyncThunk(
  `gwl/fieldsheet/${GroundWaterLevel.CREATE_NEW_SCHEDULE_FIELD}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.groundWaterLevel.CREATE_NEW_SCHEDULE_FIELD,
        method: "POST",
        header: "content-type: application/json",
        data,
      });
      toast.success("Added new schedule field work successfully.");
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get list ground water level
 */
export const getFieldSheetData = createAsyncThunk(
  `gwl/fieldsheet/${GroundWaterLevel.GET_FIELD_DATA_SHEET}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url:
          endpoints.groundWaterLevel.GET_FIELD_SHEET_DATA +
          `/${data.piezometerid}`,
        method: "POST",
        header: "content-type: application/json",
        data: data.data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get list ground water level
 */
export const getFieldSheetDataGraph = createAsyncThunk(
  `gwl/fieldsheet/${GroundWaterLevel.GET_FIELD_DATA_SHEET_GRAPH}`,
  async (piezometerid: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url:
          endpoints.groundWaterLevel.GET_FIELD_SHEET_DATA_GRAPH +
          `/${piezometerid}`,
        method: "GET",
        header: "content-type: application/json",
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get list ground water level
 */
export const getDataLevelLoggerHeader = createAsyncThunk(
  `gwl/fieldsheet/${GroundWaterLevel.GET_DATA_LEVEL_LOGGER_HEADER}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url:
          endpoints.groundWaterLevel.GET_DATA_LEVEL_LOGGER +
          `/${data.piezometerid}/header`,
        method: "POST",
        header: "content-type: application/json",
        data: data.data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);
/**
 * Function get list ground water level
 */
export const getDataRainFallHeader = createAsyncThunk(
  `gwl/fieldsheet/${GroundWaterLevel.GET_DATA_RAIN_FALL_HEADER}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url:
          endpoints.groundWaterLevel.GET_DATA_RAIN_FALL_PIEZOMETER +
          `/${data.piezometerid}/header`,
        method: "POST",
        header: "content-type: application/json",
        data: data.data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get list ground water level
 */
export const getDataLevelLoggerDetail = createAsyncThunk(
  `gwl/fieldsheet/${GroundWaterLevel.GET_DATA_LEVEL_LOGGER_DETAIL}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url:
          endpoints.groundWaterLevel.GET_DATA_LEVEL_LOGGER +
          `/${data.piezometerid}/detail`,
        method: "POST",
        header: "content-type: application/json",
        data: data.data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get list ground water level
 */
export const getDataRainFallDetail = createAsyncThunk(
  `gwl/fieldsheet/${GroundWaterLevel.GET_DATA_RAIN_FALL_DETAIL}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url:
          endpoints.groundWaterLevel.GET_DATA_RAIN_FALL_PIEZOMETER +
          `/${data.piezometerid}/detail`,
        method: "POST",
        header: "content-type: application/json",
        data: data.data,
      });

      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get list ground water level
 */
export const deleteDataLevelLogger = createAsyncThunk(
  `gwl/fieldsheet/${GroundWaterLevel.DELETE_LEVEL_LOGGER_DATA}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url:
          endpoints.groundWaterLevel.DELETE_DATA_LEVEL_LOGGER +
          `/${data.piezometer_id}/${data.filename}`,
        method: "DELETE",
        header: "content-type: application/json",
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get list ground water level
 */
export const getDataMasterTable = createAsyncThunk(
  `gwl/fieldsheet/${GroundWaterLevel.GET_DATA_MASTER_TABLE}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url:
          endpoints.groundWaterLevel.GET_DATA_MASTER_TABLE +
          `/${data.piezometer_id}`,
        method: "POST",
        header: "content-type: application/json",
        data: data.data,
        cancelToken: data.cancelToken,
      });
      const responseUrl = await fetch(response.data.data);
      const jsonData = await responseUrl.json();

      jsonData["responseUrl"] = responseUrl;
      localStorage.setItem("CHANGE_SITE_VISIT", "false");
      localStorage.setItem("requestMasterApiOnTime", "true");
      localStorage.setItem("requestMasterApiOnTimeTable", "true");

      if (responseUrl.status === 200) {
        clearTimeout(data.timeOutCallBack);
      }

      if (jsonData.data === null) {
        toast.warning("LevelLogger has no data");
      }
      if (
        jsonData.graph === null &&
        data.dataSiteVisit !== "Select all range"
      ) {
        toast.warning("Unable to mapping levellogger and fieldsheet data");
      }

      if (
        data.dataSiteVisit === "Select all range" &&
        jsonData.graph === null
      ) {
        toast.warning(
          "Cannot generate graphs due to inconsistency between field and level logger data (dates). Review (update or remove) field data in mobile application to resolve"
        );
      }

      return jsonData;
    } catch (error: any) {
      localStorage.setItem("requestMasterApiOnTime", "false");
      localStorage.setItem("requestMasterApiOnTimeTable", "false");

      if (error) {
        let errorMessage = "Internal Server Error";
        if (error?.data?.code) {
          errorMessage = error.data.code;
        }
        return rejectWithValue(errorMessage);
      }
    }
  }
);

/**
 * Function get list ground water level
 */
export const getDataMasterTableTimeout = createAsyncThunk(
  `gwl/fieldsheet/${GroundWaterLevel.GET_DATA_MASTER_TABLE_TIME_OUT}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url:
          endpoints.groundWaterLevel.GET_DATA_MASTER_TABLE_TIME_OUT +
          `/${data.piezometer_id}/${data.site_visit_from}/${data.site_visit_to}`,
        method: "GET",
        header: "content-type: application/json",
      });
      const responseUrl = await fetch(response.data.data);
      const jsonData = await responseUrl.json();

      jsonData["responseUrl"] = responseUrl;

      return jsonData;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);
/**
 * Function get list ground water level
 */
export const getListMasterTable = createAsyncThunk(
  `gwl/fieldsheet/${GroundWaterLevel.GET_VISIT_DATA}`,
  async (piezometer_id: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.groundWaterLevel.GET_VISIT_DATA + `/${piezometer_id}`,
        method: "GET",
        header: "content-type: application/json",
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get list ground water level
 */
export const getListRainFail = createAsyncThunk(
  `gwl/fieldsheet/${GroundWaterLevel.GET_RAINFALL_LIST}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.groundWaterLevel.GET_RAINFALL_LIST,
        method: "POST",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get list ground water level
 */
export const getListResultTable = createAsyncThunk(
  `gwl/resulttable/${GroundWaterLevel.GET_RESULT_TABLE_LIST}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.groundWaterLevel.GET_DATA_RESULT_DATA,
        method: "POST",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function update reviewer information into ResultTable
 */
export const approveResultTableData = createAsyncThunk(
  `gwl/resulttable/${GroundWaterLevel.APPROVE_RESULT_TABLE_DATA}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.groundWaterLevel.APPROVE_RESULT_TABLE_DATA,
        method: "POST",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function update reviewer information into ResultTable
 */
export const unapproveResultTableData = createAsyncThunk(
  `gwl/resulttable/${GroundWaterLevel.UNAPPROVED_RESULT_TABLE_DATA}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.groundWaterLevel.UNAPPROVE_RESULT_TABLE_DATA,
        method: "POST",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get group reviewer user data
 */

export const getGroupReviewerData = createAsyncThunk(
  `gwl/auth/${GroundWaterLevel.GROUP_REVIEWER_DATA}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.groundWaterLevel.GROUP_REVIEWER_DATA,
        method: "GET",
        header: "content-type: application/json",
      });

      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function update approved information into MasterTableReviewer
 */
export const approveMasterTableData = createAsyncThunk(
  `gwl/mastertable/${GroundWaterLevel.APPROVE_MASTER_TABLE_DATA}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.groundWaterLevel.APPROVE_MASTER_TABLE_DATA,
        method: "POST",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function update unapproved information into MasterTableReviewer
 */
export const unapprovedMasterTableData = createAsyncThunk(
  `gwl/mastertable/${GroundWaterLevel.UNAPPROVE_MASTER_TABLE_DATA}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.groundWaterLevel.UNAPPROVE_MASTER_TABLE_DATA,
        method: "POST",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get group reviewer user data
 */

export const getMasterTableReviewerData = createAsyncThunk(
  `gwl/mastertable/${GroundWaterLevel.GET_MASTER_TABLE_REVIEWER_DATA}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url:
          endpoints.groundWaterLevel.GET_MASTER_TABLE_REVIEWER_DATA +
          `/${data?.piezometer_id}/${data?.site_visit_from}/${data?.site_visit_to}`,
        method: "GET",
        header: "content-type: application/json",
      });

      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get group reviewer user data
 */

export const getDataAttributes = createAsyncThunk(
  `gwl/mastertable/${GroundWaterLevel.GET_DATA_ATTRIBUTES}`,
  async (piezometer_id: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url:
          endpoints.groundWaterLevel.GET_DATA_ATTRIBUTES + `/${piezometer_id}`,
        method: "GET",
        header: "content-type: application/json",
      });

      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/** @type {object} init state of pagination */
export const pagination = {
  page: 1,
  pagesize: 10,
  // pagesize: 10,
  total: 0,
};

/** @type {object} init state of cocs */
const initialState = {
  loading: false,
  projectsNumberList: [],
  listGroundWaterLevel: [],
  listBoreholeID: [],
  paginationScheduleFieldWork: pagination,
  paginationLevelLoggerData: pagination,
  error: "",
  projectNumberSearch: undefined,
  boreHoleIDSearch: undefined,
  loadingBoreholeID: false,
  loadingSceen: false,
  screenSearch: undefined,
  siteVisitSearch: undefined,
  dateSearch: undefined,
  loadingLevelLogger: false,
  listLevelLogger: [],
  fileNameUpload: undefined,
  fileUpload: undefined,
  filePath: undefined,
  listProjectNumberCoreGS: [],
  projectNumberCoreGS: undefined,
  boreholeIDCoreGS: undefined,
  listScreenCoreGS: [],
  loadingScreenCoreGS: false,
  screenCoreGS: undefined,
  typeScheduleField: undefined,
  loadingFieldData: false,
  listFieldData: [],
  paginationFieldData: pagination,
  loadingLevelLoggerHeader: false,
  paginationLevelLoggerHeader: pagination,
  listDataLevelLoggerHeader: [],
  loadingLevelLoggerDetail: false,
  paginationLevelLoggerDetail: pagination,
  listDataLevelLoggerDetail: [],
  listFieldDataGraph: [],
  loadingFieldDataGraph: false,
  listMasterData: [],
  loadingMasterData: false,
  listVisitData: [],
  loadingVisitData: false,
  exportExcelData: undefined,
  listGraphMasterData: [],
  listSWLGraphMasterData: [],
  reponseUrlMasterData: undefined,
  loadingRainFall: false,
  listRainFall: [],
  paginationRainFall: pagination,
  loadingRainFallHeader: false,
  paginationRainFallHeader: pagination,
  listDataRainFallHeader: [],
  loadingRainFallDetail: false,
  paginationRainFallDetail: pagination,
  listDataRainFallDetail: [],
  projectNumberSearchLevelLogger: undefined,
  boreHoleIDSearchLevelLogger: undefined,
  projectNumberSearchRainFall: undefined,
  boreHoleIDSearchRainFall: undefined,
  loadingResultTable: false,
  paginationResultTable: pagination,
  listDataResultTable: [],
  projectNumberSearchResultTable: undefined,
  boreHoleIDSearchResultTable: undefined,
  recordFrom: undefined,
  recordTo: undefined,
  groupReviewerData: undefined,
  masterTableReviewerData: undefined,
  loadingAttributes: false,
  dataAttributes: undefined,
  graphTitle: "",
  graphTitleMaster: "",
  isSearch: false,
};

const groundWaterlevel = createSlice({
  name: "coc",
  initialState,
  reducers: {
    resetPerPage: (state) => {
      state.paginationScheduleFieldWork = {
        ...state.paginationScheduleFieldWork,
        pagesize: 10,
      };
    },
    setClearSearch: (state, action) => {
      state.projectNumberSearch = undefined;
      state.boreHoleIDSearch = undefined;
      state.screenSearch = undefined;
      state.fileNameUpload = undefined;
      state.fileUpload = undefined;
      state.filePath = undefined;
      state.siteVisitSearch = undefined;
      state.dateSearch = undefined;
      state.listBoreholeID = [];
      state.projectNumberSearchLevelLogger = undefined;
      state.boreHoleIDSearchLevelLogger = undefined;
      state.projectNumberSearchRainFall = undefined;
      state.boreHoleIDSearchRainFall = undefined;
      state.projectNumberSearchResultTable = undefined;
      state.boreHoleIDSearchResultTable = undefined;
      state.recordFrom = undefined;
      state.recordTo = undefined;
      state.isSearch = false;
    },
    setClearFieldSearch: (state, action) => {
      state.projectNumberSearch = undefined;
      state.boreHoleIDSearch = undefined;
      state.screenSearch = undefined;
      state.fileNameUpload = undefined;
      state.fileUpload = undefined;
      state.filePath = undefined;
      state.siteVisitSearch = undefined;
    },
    setClearAllFiledQuery: (state, action) => {
      state.projectNumberSearch = undefined;
      state.boreHoleIDSearch = undefined;
      state.screenSearch = undefined;
      state.fileNameUpload = undefined;
      state.fileUpload = undefined;
      state.filePath = undefined;
      state.siteVisitSearch = undefined;
      state.dateSearch = undefined;
      state.listBoreholeID = [];
      state.projectNumberSearchLevelLogger = undefined;
      state.boreHoleIDSearchLevelLogger = undefined;
      state.projectNumberSearchRainFall = undefined;
      state.boreHoleIDSearchRainFall = undefined;
      state.projectNumberSearchResultTable = undefined;
      state.boreHoleIDSearchResultTable = undefined;
      state.recordFrom = undefined;
      state.recordTo = undefined;
      state.paginationScheduleFieldWork = pagination;
      state.paginationLevelLoggerData = pagination;
      state.paginationFieldData = pagination;
      state.paginationLevelLoggerHeader = pagination;
      state.paginationLevelLoggerDetail = pagination;
      state.paginationRainFall = pagination;
      state.paginationRainFallHeader = pagination;
      state.paginationRainFallDetail = pagination;
      state.paginationResultTable = pagination;
      state.isSearch = false;
    },
    setProjectNumberSearch: (state, action) => {
      state.projectNumberSearch = action.payload;
      state.boreHoleIDSearch = undefined;
      state.screenSearch = undefined;
      state.siteVisitSearch = undefined;
    },
    setBoreHoleIDSearch: (state, action) => {
      state.boreHoleIDSearch = action.payload;
      state.screenSearch = undefined;
      state.siteVisitSearch = undefined;
    },
    setScreenSearch: (state, action) => {
      state.screenSearch = action.payload;
      state.siteVisitSearch = undefined;
    },
    setSiteVisitSearch: (state, action) => {
      state.siteVisitSearch = action.payload;
    },
    setDataFileUpload: (state, action) => {
      state.fileNameUpload = action.payload.fileNameUpload;
      state.fileUpload = action.payload.fileUpload;
      state.filePath = action.payload.filePath;
    },
    setProjectNumberCoreGS: (state, action) => {
      state.projectNumberCoreGS = action.payload;
      state.boreholeIDCoreGS = undefined;
      state.screenCoreGS = undefined;
    },
    setBoreholeIDCoreGS: (state, action) => {
      state.boreholeIDCoreGS = action.payload;
      state.screenCoreGS = undefined;
    },
    setScreenCoreGS: (state, action) => {
      state.screenCoreGS = action.payload;
    },
    setTypeScheduleFieldGS: (state, action) => {
      state.typeScheduleField = action.payload;
      state.screenCoreGS = undefined;
    },
    setClearAddScheckFieldGS: (state, action) => {
      state.projectNumberCoreGS = undefined;
      state.boreholeIDCoreGS = undefined;
      state.screenCoreGS = undefined;
      state.typeScheduleField = undefined;
    },
    setDateSearchData: (state, action) => {
      state.dateSearch = action.payload;
    },
    setProjectNumberSearchLevelLogge: (state, action) => {
      state.projectNumberSearchLevelLogger = action.payload;
      state.boreHoleIDSearchLevelLogger = undefined;
    },
    setBoreHoleIDSearchLevelLogge: (state, action) => {
      state.boreHoleIDSearchLevelLogger = action.payload;
    },
    setProjectNumberSearchRainFall: (state, action) => {
      state.projectNumberSearchRainFall = action.payload;
      state.boreHoleIDSearchRainFall = undefined;
    },
    setBoreHoleIDSearchRainFall: (state, action) => {
      state.boreHoleIDSearchRainFall = action.payload;
    },
    setProjectNumberSearchResultTable: (state, action) => {
      state.projectNumberSearchResultTable = action.payload;
      state.boreHoleIDSearchResultTable = undefined;
    },
    setBoreHoleIDSearchResultTable: (state, action) => {
      state.boreHoleIDSearchResultTable = action.payload;
    },
    setRecordFrom: (state, action) => {
      state.recordFrom = action.payload;
    },
    setRecordTo: (state, action) => {
      state.recordTo = action.payload;
    },
    setLoadingFieldData: (state, action) => {
      state.loadingFieldData = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLoadingResultTable: (state, action) => {
      state.loadingResultTable = action.payload;
    },
    setLoadingRainFall: (state, action) => {
      state.loadingRainFall = action.payload;
    },
    setLoadingMasterData: (state, action) => {
      state.loadingMasterData = action.payload;
    },
    setLoadingLevelLogger: (state, action) => {
      state.loadingLevelLogger = action.payload;
    },
    setDataMasterTableTimeout: (state, action) => {
      state.loadingMasterData = false;
      state.reponseUrlMasterData =
        action.payload?.responseUrl?.url || undefined;
      state.listMasterData = action.payload.data;
      state.listGraphMasterData =
        action.payload.graph && action.payload.graph !== null
          ? action.payload.graph
          : [];
      state.listSWLGraphMasterData =
        action.payload.graph && action.payload.swl_graph !== null
          ? action.payload.swl_graph
          : [];
      state.graphTitleMaster =
        action.payload.graph && action.payload.graph_title !== null
          ? action.payload.graph_title
          : "";
    },
    setIsSearch: (state, action) => {
      state.isSearch = action.payload;
    },
    setClearDataGraphMasterTables: (state, action) => {
      state.listGraphMasterData = action.payload;
      state.listSWLGraphMasterData = action.payload;
      state.listMasterData = action.payload;
      state.graphTitleMaster = "";
    },
  },
  extraReducers: {
    [getListGroundWaterLevel.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getListGroundWaterLevel.rejected.toString()]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getListGroundWaterLevel.fulfilled.toString()]: (state, action) => {
      state.listGroundWaterLevel = action.payload.data.items;
      state.paginationScheduleFieldWork = {
        page: action.payload?.page || 1,
        pagesize: action.payload?.pagesize || 10,
        total: action.payload?.total || 0,
      };
      state.error = "";
      state.listMasterData = [];
      state.listGraphMasterData = [];
      state.listSWLGraphMasterData = [];
      state.reponseUrlMasterData = undefined;
      state.listFieldDataGraph = [];
      state.listLevelLogger = [];
      state.listVisitData = [];
      state.loading = false;
    },
    [getListProjectNumber.rejected.toString()]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getListProjectNumber.fulfilled.toString()]: (state, action) => {
      state.projectsNumberList = action.payload.project_number_list;
      state.error = "";
    },
    [getListBoreholeID.pending.toString()]: (state) => {
      state.loadingBoreholeID = true;
    },
    [getListBoreholeID.rejected.toString()]: (state, action) => {
      state.error = action.payload;
      state.loadingBoreholeID = false;
    },
    [getListBoreholeID.fulfilled.toString()]: (state, action) => {
      state.listBoreholeID = action.payload.datas;
      state.error = "";
      state.loadingBoreholeID = false;
    },
    [getListLevelLogger.pending.toString()]: (state) => {
      state.loadingLevelLogger = true;
    },
    [getListLevelLogger.rejected.toString()]: (state, action) => {
      state.error = action.payload;
      state.loadingLevelLogger = false;
    },
    [getListLevelLogger.fulfilled.toString()]: (state, action) => {
      state.listLevelLogger = action.payload.data.items;
      state.paginationLevelLoggerData = {
        page: action.payload?.page || 1,
        pagesize: action.payload?.pagesize || 10,
        total: action.payload?.total || 0,
      };
      state.loadingLevelLogger = false;
      state.error = "";
    },
    [uploadLevelLogger.pending.toString()]: (state) => {
      state.loadingLevelLogger = true;
    },
    [uploadLevelLogger.rejected.toString()]: (state, action) => {
      state.error = action.payload;
      state.loadingLevelLogger = false;
    },
    [uploadLevelLogger.fulfilled.toString()]: (state, action) => {
      state.projectNumberSearch = undefined;
      state.boreHoleIDSearch = undefined;
      state.screenSearch = undefined;
      state.fileNameUpload = undefined;
      state.fileUpload = undefined;
      state.error = "";
    },
    [getProjectNumberCoreGS.pending.toString()]: (state) => {},
    [getProjectNumberCoreGS.rejected.toString()]: (state, action) => {
      state.error = action.payload;
    },
    [getProjectNumberCoreGS.fulfilled.toString()]: (state, action) => {
      state.listProjectNumberCoreGS = action.payload.records;
      state.error = "";
    },
    [getScreenCoreGS.pending.toString()]: (state) => {
      state.loadingScreenCoreGS = true;
    },
    [getScreenCoreGS.rejected.toString()]: (state, action) => {
      state.loadingScreenCoreGS = false;
      state.error = action.payload;
    },
    [getScreenCoreGS.fulfilled.toString()]: (state, action) => {
      state.listScreenCoreGS = action.payload.records;
      state.loadingScreenCoreGS = false;
      state.error = "";
    },
    [createScheduleFieldGS.pending.toString()]: (state) => {
      state.loading = true;
    },
    [createScheduleFieldGS.rejected.toString()]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [createScheduleFieldGS.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.error = "";
    },
    [getFieldSheetData.pending.toString()]: (state) => {
      state.loadingFieldData = true;
    },
    [getFieldSheetData.rejected.toString()]: (state, action) => {
      state.loadingFieldData = false;
      state.error = action.payload;
    },
    [getFieldSheetData.fulfilled.toString()]: (state, action) => {
      state.loadingFieldData = false;
      state.listFieldData = action.payload.data.items;
      state.paginationFieldData = {
        page: action.payload?.page || 1,
        pagesize: action.payload?.pagesize || 10,
        total: action.payload?.total || 0,
      };
      state.error = "";
    },
    [getListRainFail.pending.toString()]: (state) => {
      state.loadingRainFall = true;
    },
    [getListRainFail.rejected.toString()]: (state, action) => {
      state.loadingRainFall = false;
      state.error = action.payload;
    },
    [getListRainFail.fulfilled.toString()]: (state, action) => {
      state.loadingRainFall = false;
      state.listFieldData = action.payload.data.items;
      state.paginationRainFall = {
        page: action.payload?.page || 1,
        pagesize: action.payload?.pagesize || 10,
        total: action.payload?.total || 0,
      };
      state.error = "";
    },
    [getDataLevelLoggerHeader.pending.toString()]: (state) => {
      state.loadingLevelLoggerHeader = true;
    },
    [getDataLevelLoggerHeader.rejected.toString()]: (state, action) => {
      state.loadingLevelLoggerHeader = false;
      state.error = action.payload;
    },
    [getDataLevelLoggerHeader.fulfilled.toString()]: (state, action) => {
      state.loadingLevelLoggerHeader = false;
      state.listDataLevelLoggerHeader = action.payload.data.items;
      state.paginationLevelLoggerHeader = {
        page: action.payload?.page || 1,
        pagesize: action.payload?.pagesize || 10,
        total: action.payload?.total || 0,
      };
      state.error = "";
    },
    [getDataLevelLoggerDetail.pending.toString()]: (state) => {
      state.loadingLevelLoggerDetail = true;
    },
    [getDataLevelLoggerDetail.rejected.toString()]: (state, action) => {
      state.loadingLevelLoggerDetail = false;
      state.error = action.payload;
    },
    [getDataLevelLoggerDetail.fulfilled.toString()]: (state, action) => {
      state.loadingLevelLoggerDetail = false;
      state.listDataLevelLoggerDetail = action.payload.data.items;
      state.paginationLevelLoggerDetail = {
        page: action.payload?.page || 1,
        pagesize: action.payload?.pagesize || 10,
        total: action.payload?.total || 0,
      };
      state.error = "";
    },
    [getFieldSheetDataGraph.pending.toString()]: (state) => {
      state.loadingFieldDataGraph = true;
    },
    [getFieldSheetDataGraph.rejected.toString()]: (state, action) => {
      state.loadingFieldDataGraph = false;
      state.error = action.payload;
    },
    [getFieldSheetDataGraph.fulfilled.toString()]: (state, action) => {
      state.loadingFieldDataGraph = false;
      state.listFieldDataGraph = action.payload.data;
      state.graphTitle = action.payload.graph_title;
      state.error = "";
    },
    [getDataMasterTable.pending.toString()]: (state) => {
      state.loadingMasterData = true;
    },
    [getDataMasterTable.rejected.toString()]: (state, action) => {
      state.loadingMasterData = false;
      state.error = action.payload;
    },
    [getDataMasterTable.fulfilled.toString()]: (state, action) => {
      state.loadingMasterData = false;
      state.reponseUrlMasterData =
        action.payload?.responseUrl?.url || undefined;
      state.listMasterData = action.payload.data;
      state.listGraphMasterData =
        action.payload.graph && action.payload.graph !== null
          ? action.payload.graph
          : [];
      state.listSWLGraphMasterData =
        action.payload.graph && action.payload.swl_graph !== null
          ? action.payload.swl_graph
          : [];
      state.graphTitleMaster = action.payload.graph_title;
      state.error = "";
    },
    [getDataMasterTableTimeout.pending.toString()]: (state) => {
      state.loadingMasterData = true;
    },
    [getDataMasterTableTimeout.rejected.toString()]: (state, action) => {
      state.loadingMasterData = false;
      state.error = action.payload;
    },
    [getDataMasterTableTimeout.fulfilled.toString()]: (state, action) => {
      state.loadingMasterData = false;
      state.reponseUrlMasterData =
        action.payload?.responseUrl?.url || undefined;
      state.listMasterData = action.payload.data;
      state.listGraphMasterData =
        action.payload.graph && action.payload.graph !== null
          ? action.payload.graph
          : [];
      state.listSWLGraphMasterData =
        action.payload.graph && action.payload.swl_graph !== null
          ? action.payload.swl_graph
          : [];
      state.error = "";
    },
    [getListMasterTable.pending.toString()]: (state) => {
      state.loadingVisitData = true;
    },
    [getListMasterTable.rejected.toString()]: (state, action) => {
      state.loadingVisitData = false;
      state.error = action.payload;
    },
    [getListMasterTable.fulfilled.toString()]: (state, action) => {
      state.loadingVisitData = false;
      state.listVisitData = action.payload.results;
      state.error = "";
    },
    [getDataRainFallHeader.pending.toString()]: (state) => {
      state.loadingRainFallHeader = true;
    },
    [getDataRainFallHeader.rejected.toString()]: (state, action) => {
      state.loadingRainFallHeader = false;
      state.error = action.payload;
    },
    [getDataRainFallHeader.fulfilled.toString()]: (state, action) => {
      state.loadingRainFallHeader = false;
      state.listDataRainFallHeader = action.payload.data.items;
      state.paginationRainFallHeader = {
        page: action.payload?.page || 1,
        pagesize: action.payload?.pagesize || 10,
        total: action.payload?.total || 0,
      };
      state.error = "";
    },
    [getDataRainFallDetail.pending.toString()]: (state) => {
      state.loadingRainFallDetail = true;
    },
    [getDataRainFallDetail.rejected.toString()]: (state, action) => {
      state.loadingRainFallDetail = false;
      state.error = action.payload;
    },
    [getDataRainFallDetail.fulfilled.toString()]: (state, action) => {
      state.loadingRainFallDetail = false;
      state.listDataRainFallDetail = action.payload.data.items;
      state.paginationRainFallDetail = {
        page: action.payload?.page || 1,
        pagesize: action.payload?.pagesize || 10,
        total: action.payload?.total || 0,
      };
      state.error = "";
    },
    [getListResultTable.pending.toString()]: (state) => {
      state.loadingResultTable = true;
    },
    [getListResultTable.rejected.toString()]: (state, action) => {
      state.loadingResultTable = false;
      state.error = action.payload;
    },
    [getListResultTable.fulfilled.toString()]: (state, action) => {
      state.loadingResultTable = false;
      state.listDataResultTable = action.payload.data.items;
      state.paginationResultTable = {
        page: action.payload?.page || 1,
        pagesize: action.payload?.pagesize || 10,
        total: action.payload?.total || 0,
      };
      state.error = "";
    },
    [approveResultTableData.pending.toString()]: (state) => {
      state.loading = true;
    },
    [approveResultTableData.rejected.toString()]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [approveResultTableData.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.error = "";
    },
    [unapproveResultTableData.pending.toString()]: (state) => {
      state.loading = true;
    },
    [unapproveResultTableData.rejected.toString()]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [unapproveResultTableData.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.error = "";
    },
    [getGroupReviewerData.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getGroupReviewerData.rejected.toString()]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getGroupReviewerData.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.groupReviewerData = action.payload;
      state.error = "";
    },
    [approveMasterTableData.pending.toString()]: (state) => {
      state.loading = true;
    },
    [approveMasterTableData.rejected.toString()]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [approveMasterTableData.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.error = "";
    },
    [unapprovedMasterTableData.pending.toString()]: (state) => {
      state.loading = true;
    },
    [unapprovedMasterTableData.rejected.toString()]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [unapprovedMasterTableData.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.error = "";
    },
    [getMasterTableReviewerData.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getMasterTableReviewerData.rejected.toString()]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getMasterTableReviewerData.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.masterTableReviewerData = action.payload.data;
      state.error = "";
    },
    [getDataAttributes.pending.toString()]: (state) => {
      state.loadingAttributes = true;
    },
    [getDataAttributes.rejected.toString()]: (state, action) => {
      state.loadingAttributes = false;
      state.error = action.payload;
    },
    [getDataAttributes.fulfilled.toString()]: (state, action) => {
      state.loadingAttributes = false;
      state.dataAttributes = action.payload;
      state.error = "";
    },
  },
});

export const {
  resetPerPage,
  setProjectNumberSearch,
  setBoreHoleIDSearch,
  setScreenSearch,
  setSiteVisitSearch,
  setClearSearch,
  setDataFileUpload,
  setProjectNumberCoreGS,
  setBoreholeIDCoreGS,
  setClearAddScheckFieldGS,
  setScreenCoreGS,
  setTypeScheduleFieldGS,
  setDateSearchData,
  setProjectNumberSearchLevelLogge,
  setBoreHoleIDSearchLevelLogge,
  setClearAllFiledQuery,
  setProjectNumberSearchRainFall,
  setBoreHoleIDSearchRainFall,
  setProjectNumberSearchResultTable,
  setBoreHoleIDSearchResultTable,
  setRecordFrom,
  setRecordTo,
  setLoadingFieldData,
  setLoadingResultTable,
  setLoadingRainFall,
  setClearFieldSearch,
  setLoadingMasterData,
  setLoadingLevelLogger,
  setDataMasterTableTimeout,
  setLoading,
  setIsSearch,
  setClearDataGraphMasterTables,
} = groundWaterlevel.actions;

const { reducer: groundWaterLevel } = groundWaterlevel;
export default groundWaterLevel;
