import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API_P2 from "service/api_p2";
import endpoints from "../service/endpoints";
import { LandFillGas } from "./actionTypes";
import { toast } from "react-toastify";

/**
 * Function get list WellID by site_name
 */
export const getWellSiteName = createAsyncThunk(
  `land-fill/well-data/${LandFillGas.GET_WELL_SITE_NAMES}`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await API_P2({
        url: endpoints.landFillGas.WELL_DATA_GET_SITE_NAME,
        method: "GET",
        header: "content-type: application/json"
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);


/**
 * Function search Well List
 */
export const searchWellList = createAsyncThunk(
  `land-fill/well-data/${LandFillGas.SEARCH_WELL_LIST}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API_P2({
        url: endpoints.landFillGas.WELL_DATA_SEARCH,
        method: "POST",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

export const searchWellPhoto = createAsyncThunk(
  `land-fill/well-data/${LandFillGas.SEARCH_WELL_PHOTO}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API_P2({
        url: endpoints.landFillGas.WELL_PHOTO_SEARCH,
        method: "POST",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);


export const downloadWellPhoto = createAsyncThunk(
  `land-fill/well-data/${LandFillGas.DOWNLOAD_WELL_PHOTO}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API_P2({
        url: endpoints.landFillGas.WELL_PHOTO_DOWNLOAD,
        method: "POST",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

export const exportWellPhoto = createAsyncThunk(
  `land-fill/well-data/${LandFillGas.EXPORT_WELL_PHOTO}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API_P2({
        url: endpoints.landFillGas.WELL_PHOTO_EXPORT,
        method: "POST",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

export const exportWellPhotoPDF = createAsyncThunk(
  `land-fill/well-data/${LandFillGas.EXPORT_WELL_PHOTO_PDF}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API_P2({
        url: endpoints.landFillGas.WELL_PHOTO_EXPORT_PDF,
        method: "POST",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

// WELL GAS DATA
export const getWellRawData = createAsyncThunk(
  `land-fill/well-data/${LandFillGas.WELL_DATA_GET_RAW_DATA}`,
  async (id: any, { rejectWithValue }) => {
    try {
      const response = await API_P2({
        url: endpoints.landFillGas.WELL_DATA_GET_RAW_DATA + id,
        method: "GET",
        header: "content-type: application/json"
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

// WELL ATTRIBUTE DATA
export const getWellAttributeData = createAsyncThunk(
  `land-fill/well-data/${LandFillGas.WELL_DATA_GET_ATTRIBUTE_DATA}`,
  async (id: any, { rejectWithValue }) => {
    try {
      const response = await API_P2({
        url: endpoints.landFillGas.WELL_DATA_GET_ATTRIBUTE_DATA + id,
        method: "GET",
        header: "content-type: application/json"
      });

      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

// WELL SURVERY DATA
export const getWellSurveyData = createAsyncThunk(
  `land-fill/well-data/${LandFillGas.WELL_DATA_GET_SURVEY_DATA}`,
  async (id: any, { rejectWithValue }) => {
    try {
      const response = await API_P2({
        url: endpoints.landFillGas.WELL_DATA_GET_SURVEY_DATA + id,
        method: "GET",
        header: "content-type: application/json"
      });

      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

export const deleteWellData = createAsyncThunk(
  `land-fill/well-data/${LandFillGas.WELL_DATA_DELETE}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API_P2({
        url: endpoints.landFillGas.WELL_DATA_DELETE,
        method: "POST",
        header: "content-type: application/json",
        data
      });
      toast.success("Delete data successfully.");
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);


// WELL MANULA DATA
export const searchWellManualDataImport = createAsyncThunk(
  `land-fill/well-data/${LandFillGas.WELL_MANUAL_SEARCH}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API_P2({
        url: endpoints.landFillGas.WELL_MANUAL_SEARCH,
        method: "POST",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);


// LFG GENERATE RESULT TABLE
export const generateLfgResultTable = createAsyncThunk(
  `land-fill/well-data/${LandFillGas.WELL_GENERATE_RESULT_TABLE}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API_P2({
        url: endpoints.landFillGas.WELL_GENERATE_RESULT_TABLE,
        method: "POST",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

// VI PHOTO
export const searchVIPhoto = createAsyncThunk(
  `land-fill/vi-photo/${LandFillGas.SEARCH_VI_PHOTO}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API_P2({
        url: endpoints.landFillGas.VI_PHOTO_SEARCH,
        method: "POST",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

export const downloadVIPhoto = createAsyncThunk(
  `land-fill/vi-photo/${LandFillGas.DOWNLOAD_VI_PHOTO}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API_P2({
        url: endpoints.landFillGas.VI_PHOTO_DOWNLOAD,
        method: "POST",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

export const getVISiteName = createAsyncThunk(
  `land-fill/well-data/${LandFillGas.GET_SITENAMES_VI_COMMENT}`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await API_P2({
        url: endpoints.landFillGas.GET_SITENAMES_VI_COMMENT,
        method: "GET",
        header: "content-type: application/json"
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

export const exportVIPhoto = createAsyncThunk(
  `land-fill/vi-photo/${LandFillGas.EXPORT_VI_PHOTO}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API_P2({
        url: endpoints.landFillGas.VI_PHOTO_EXPORT,
        method: "POST",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

export const exportVIPhotoPDF = createAsyncThunk(
  `land-fill/vi-photo/${LandFillGas.EXPORT_VI_PHOTO_PDF}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API_P2({
        url: endpoints.landFillGas.VI_PHOTO_EXPORT_PDF,
        method: "POST",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

// VI COMMENT
export const searchVIComment = createAsyncThunk(
  `land-fill/vi-comment/${LandFillGas.SEARCH_VI_COMMENT}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API_P2({
        url: endpoints.landFillGas.VI_COMMNENT_SEARCH,
        method: "POST",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);
/** @type {object} init state of pagination */
export const pagination = {
  page: 1,
  pagesize: 10,
  // pagesize: 10,
  total: 0,
};


/** @type {object} init state of parameter */
export const initialDataWellList = {
  date: undefined,
  site_name: undefined,
  well_id: undefined,
  operator: undefined,
  atmospheric_pressure_mb: undefined,
  atmospheric_conditions: undefined,
  equipment: undefined,
  method: undefined,
  weather: undefined,
  comments: undefined
};

/** @type {object} init state of cocs */
const initialState = {
  loading: false,
  listWellData: [],
  paginationWellList: pagination,
  listWellID: [],
  loadingWellListID: false,

  siteNameSearch: undefined,
  startDateSearch: undefined,
  endDateSearch: undefined,
  jobNumberSearch: [],
  wellIDSearch: [],
  operatorSearch: undefined,
  isSearch: false,
  rememberSearchWillList: undefined,
  listSiteName: [],

  error: "",
  wellListSelected: [],

  // well-photo
  siteNameSearchWP: undefined,
  startDateSearchWP: undefined,
  endDateSearchWP: undefined,
  wellIDSearchWP: [],
  jobNumberSearchWP: [],
  operatorSearchWP: undefined,
  isSearchWP: false,
  loadingWPList: false,
  listWP: [],
  paginationWPList: pagination,
  dataSelectWPs: [],
  loadingExportWP: false,
  rememberSearchWP: undefined,

  loadingRawData: false,
  wellRawData: [],

  loadingWellGasData: false,

  // Update well attribute data
  loadingWellAttributes: false,
  dataWellAttributes: undefined,

  // Update Well Survey Data
  loadingWellSurveyData: false,
  dataWellSurveyData: undefined,

  // Well Manual Import
  loadingSearchMID: false,
  listWellDataListMID: [],
  paginationListMID: pagination,

  uploadBySearchMID: undefined,
  uploadDateSearchMID: undefined,
  fileNameUpload: undefined,
  fileUpload: undefined,
  filePath: undefined,
  allowOverwriteData: undefined,

  isSearchMID: false,
  rememberSearchMID: undefined,
  // Lfg Result Table
  lfgResultTableData: [],
  loadingLfgResultTable: false,

  // VI photos
  loadingVIPhoto: false,
  paginationVIPhotoList: pagination,
  listVIPhoto: [],
  dataSelectVIPhotos: [],
  siteNameSearchVIPhoto: undefined,
  dateSearchVIPhoto: undefined,
  typeOfObservationSearchVIPhoto: undefined,
  isSearchVIPhoto: false,
  loadingExportVIPhoto: false,
  listSiteNamesVI: [],

  // VI comment
  loadingVIComment: false,
  paginationVICommentList: pagination,
  listVIComment: [],
  viCommentsSelected: [],
  dataSelectVIComments: [],
  siteNameSearchVIComment: undefined,
  dateSearchVIComment: undefined,
  typeOfObservationSearchVIComment: undefined,
  noteSearchVIComment: undefined,
  isSearchVIComment: false,
  loadingExportVIComment: false,
  listSiteNamesVIComment: [],
  rememberSearchVIComment: undefined,

  // Generate VI Comment
  loadingGenerateVIComment: false,
  selectedGenerateVIComment: undefined,
};

const landFillGas = createSlice({
  name: "coc",
  initialState,
  reducers: {
    resetPerPage: (state) => {
      state.paginationWellList = {
        ...state.paginationWellList,
        pagesize: 10,
      };
    },
    setClearSearch: (state, action) => {
      state.siteNameSearch = undefined;
      state.startDateSearch = undefined;
      state.endDateSearch = undefined
      state.wellIDSearch = [];
      state.operatorSearch = undefined;
      state.jobNumberSearch = [];
      state.listWellID = [];
      state.isSearch = false;
      state.wellListSelected = [];
      state.rememberSearchWillList = undefined;
      //state.paginationWellList = pagination;
    },
    setSiteNameSearch: (state, action) => {
      state.siteNameSearch = action.payload;
    },
    setStartDateSearch: (state, action) => {
      state.startDateSearch = action.payload;
    },
    setEndDateSearch: (state, action) => {
      state.endDateSearch = action.payload;
    },
    setJobNumberSearch: (state, action) => {
      state.jobNumberSearch = action.payload;
    },
    setWellIDSearch: (state, action) => {
      state.wellIDSearch = action.payload;
    },
    setOperatorSearch: (state, action) => {
      state.operatorSearch = action.payload;
    },
    setRememberSearchWillList: (state, action) => {
      state.rememberSearchWillList = action.payload;
    },
    setRememberSearchMID: (state, action) => {
      state.rememberSearchMID = action.payload;
    },
    setRememberSearchWP: (state, action) => {
      state.rememberSearchWP = action.payload;
    },

    setWellListSelected: (state, action) => {
      state.wellListSelected = action.payload;
    },

    setLoading: (state, action) => {
      state.loading = action.payload;
    },

    setIsSearch: (state, action) => {
      state.isSearch = action.payload;
    },
    setIsSearchMID: (state, action) => {
      state.isSearchMID = action.payload;
    },
    setIsSearchWP: (state, action) => {
      state.isSearchWP = action.payload;
    },

    setClearDataRawData: (state, action) => {
      state.wellRawData = action.payload;
    },

    // Well Photo
    setWPClearSearch: (state, action) => {
      state.siteNameSearchWP = undefined;
      state.startDateSearchWP = undefined;
      state.endDateSearchWP = undefined;
      state.wellIDSearchWP = [];
      state.jobNumberSearchWP = [];
      state.operatorSearchWP = undefined;
      state.isSearchWP = false;
      state.loadingWPList = false;
      state.listWP = [];
      state.rememberSearchWP = undefined;
      //state.paginationWPList = pagination;
    },
    setSiteNameSearchWP: (state, action) => {
      state.siteNameSearchWP = action.payload;
    },
    setStartDateSearchWP: (state, action) => {
      state.startDateSearchWP = action.payload;
    },
    setEndDateSearchWP: (state, action) => {
      state.endDateSearchWP = action.payload;
    },
    setJobNumberSearchWP: (state, action) => {
      state.jobNumberSearchWP = action.payload;
    },
    setWellIDSearchWP: (state, action) => {
      state.wellIDSearchWP = action.payload;
    },
    setOperatorSearchWP: (state, action) => {
      state.operatorSearchWP = action.payload;
    },
    setLoadingWPList: (state, action) => {
      state.loadingWPList = action.payload;
    },
    setLoadingExportWP: (state, action) => {
      state.loadingExportWP = action.payload;
    },
    setDataSelectWPs: (state, action) => {
      state.dataSelectWPs = action.payload;
    },

    setLoadingRawData: (state, action) => {
      state.loadingRawData = action.payload;
    },

    setLoadingWellGasData: (state, action) => {
      state.loadingWellGasData = action.payload;
    },

    // MANUL IMPORT DATA  
    setUploadBySearchMID: (state, action) => {
      state.uploadBySearchMID = action.payload;
    },
    setUploadDateSearchMID: (state, action) => {
      state.uploadDateSearchMID = action.payload;
    },
    setClearSearchMID: (state, action) => {
      state.uploadBySearchMID = undefined;
      state.uploadDateSearchMID = undefined;
      state.loadingSearchMID = false;
      state.rememberSearchMID = undefined;
      //state.listWellDataListMID = []; 
    },
    setLoadingSearchMID: (state, action) => {
      state.loadingSearchMID = action.payload;
    },
    setFileNameUpload: (state, action) => {
      state.fileNameUpload = action.payload;
    },
    setfileUpload: (state, action) => {
      state.fileUpload = action.payload;
    },
    setAllowOverwriteData: (state, action) => {
      state.allowOverwriteData = action.payload;
    },

    setDataFileUpload: (state, action) => {
      state.fileNameUpload = action.payload.fileNameUpload;
      state.fileUpload = action.payload.fileUpload;
      state.filePath = action.payload.filePath;
      state.allowOverwriteData = action.payload.allowOverwriteData;
    },
    setClearDataFileUpload: (state, action) => {
      state.fileNameUpload = undefined;
      state.fileUpload = undefined;
      state.filePath = undefined;
      state.allowOverwriteData = undefined;
    },
    setLoadingLfgResultTable: (state, action) => {
      state.loadingLfgResultTable = action.payload;
    },

    // VI photo
    setLoadingVIPhoto: (state, action) => {
      state.loadingVIPhoto = action.payload;
    },
    setListVIPhoto: (state, action) => {
      state.listVIPhoto = action.payload;
    },
    setDataSelectVIPhotos: (state, action) => {
      state.dataSelectVIPhotos = action.payload;
    },
    setSiteNameSearchVIPhoto: (state, action) => {
      state.siteNameSearchVIPhoto = action.payload;
    },
    setDateSearchVIPhoto: (state, action) => {
      state.dateSearchVIPhoto = action.payload;
    },
    setTypeObservationSearchVIPhoto: (state, action) => {
      state.typeOfObservationSearchVIPhoto = action.payload;
    },
    setIsSearchVIPhoto: (state, action) => {
      state.isSearchVIPhoto = action.payload;
    },
    setVIPhotoClearSearch: (state, action) => {
      state.siteNameSearchVIPhoto = undefined;
      state.dateSearchVIPhoto = undefined;
      state.typeOfObservationSearchVIPhoto = undefined;
      state.isSearchVIPhoto = false;
      state.loadingVIPhoto = false;
      state.listVIPhoto = [];
      //state.rememberSearchWP = undefined;
      //state.paginationWPList = pagination;
    },
    setLoadingExportVIPhoto: (state, action) => {
      state.loadingExportVIPhoto = action.payload;
    },

    // VI COMMENT
    setLoadingVIComment: (state, action) => {
      state.loadingVIComment = action.payload;
    },
    setListVIComment: (state, action) => {
      state.listVIComment = action.payload;
    },
    setVICommentsSelected: (state, action) => {
      state.viCommentsSelected = action.payload;
    },
    setDataSelectVIComments: (state, action) => {
      state.dataSelectVIComments = action.payload;
    },
    setSiteNameSearchVIComment: (state, action) => {
      state.siteNameSearchVIComment = action.payload;
    },
    setDateSearchVIComment: (state, action) => {
      state.dateSearchVIComment = action.payload;
    },
    setTypeObservationSearchVIComment: (state, action) => {
      state.typeOfObservationSearchVIComment = action.payload;
    },
    setNoteSearchVIComment: (state, action) => {
      state.noteSearchVIComment = action.payload;
    },
    setIsSearchVIComment: (state, action) => {
      state.isSearchVIComment = action.payload;
    },
    setVICommentClearSearch: (state, action) => {
      state.siteNameSearchVIComment = undefined;
      state.dateSearchVIComment = undefined;
      state.typeOfObservationSearchVIComment = undefined;
      state.noteSearchVIComment = undefined;
      state.isSearchVIComment = false;
      state.loadingVIComment = false;
      state.listVIComment = [];
      state.rememberSearchVIComment = undefined;
      //state.paginationWPList = pagination;
    },
    setRememberSearchVIComment: (state, action) => {
      state.rememberSearchVIComment = action.payload;
    },

    //Generate VI Comment
    setLoadingGenerateVIComment: (state, action) => {
      state.loadingGenerateVIComment = action.payload;
    },
    setSelectedGenerateVIComment: (state, action) => {
      state.selectedGenerateVIComment = action.payload;
    }
  },

  extraReducers: {
    [searchWellList.pending.toString()]: (state) => {
      state.loading = true;
    },
    [searchWellList.rejected.toString()]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [searchWellList.fulfilled.toString()]: (state, action) => {
      state.listWellData = action.payload.data.items;
      state.paginationWellList = {
        page: action.payload?.page || 1,
        pagesize: action.payload?.pagesize || 10,
        total: action.payload?.total || 0,
      };
      state.error = "";
      state.loading = false;
    },

    [getWellSiteName.pending.toString()]: (state) => {
      state.loading = true;
      state.loadingWellListID = true;
    },
    [getWellSiteName.rejected.toString()]: (state, action) => {
      state.loading = false;
      state.loadingWellListID = false;
      state.error = action.payload;
    },
    [getWellSiteName.fulfilled.toString()]: (state, action) => {
      state.listSiteName = action.payload;
      state.error = "";
      state.loading = false;
      state.loadingWellListID = false;
    },

    // RAW DATA
    // Raw Data 
    [getWellRawData.pending.toString()]: (state) => {
      state.loadingRawData = true;
    },
    [getWellRawData.rejected.toString()]: (state, action) => {
      state.loadingRawData = false;
      state.error = action.payload;
    },
    [getWellRawData.fulfilled.toString()]: (state, action) => {
      state.loadingRawData = false;
      state.wellRawData = action.payload;
      state.error = "";
    },

    // WELL ATTRIBUTE DATA
    [getWellAttributeData.pending.toString()]: (state) => {
      state.loadingWellAttributes = true;
    },
    [getWellAttributeData.rejected.toString()]: (state, action) => {
      state.loadingWellAttributes = false;
      state.error = action.payload;
    },
    [getWellAttributeData.fulfilled.toString()]: (state, action) => {
      state.loadingWellAttributes = false;
      state.dataWellAttributes = action.payload;
      state.error = "";
    },

    // WELL SURVERY DATA
    [deleteWellData.pending.toString()]: (state) => {
      state.loadingWellGasData = true;
    },
    [deleteWellData.rejected.toString()]: (state, action) => {
      state.error = action.payload;
    },
    [deleteWellData.fulfilled.toString()]: (state, action) => {
      state.loadingWellGasData = false;
      state.error = "";
    },

    [getWellSurveyData.pending.toString()]: (state) => {
      state.loadingWellSurveyData = true;
    },
    [getWellSurveyData.rejected.toString()]: (state, action) => {
      state.loadingWellSurveyData = false;
      state.error = action.payload;
    },
    [getWellSurveyData.fulfilled.toString()]: (state, action) => {
      state.loadingWellSurveyData = false;
      state.dataWellSurveyData = action.payload;
      state.error = "";
    },

    // WELL PHOTO
    [searchWellPhoto.pending.toString()]: (state) => {
      state.loadingWPList = true;
    },
    [searchWellPhoto.rejected.toString()]: (state, action) => {
      state.loadingWPList = false;
      state.error = action.payload;
    },
    [searchWellPhoto.fulfilled.toString()]: (state, action) => {
      state.listWP = action.payload.data.items;
      state.paginationWPList = {
        page: action.payload?.page || 1,
        pagesize: action.payload?.pagesize || 10,
        total: action.payload?.total || 0,
      };
      state.error = "";
      state.loadingWPList = false;
    },

    // WELL MANUAL SEARCH
    [searchWellManualDataImport.pending.toString()]: (state) => {
      state.loadingSearchMID = true;
    },
    [searchWellManualDataImport.rejected.toString()]: (state, action) => {
      state.loadingSearchMID = false;
      state.error = action.payload;
    },
    [searchWellManualDataImport.fulfilled.toString()]: (state, action) => {
      state.listWellDataListMID = action.payload.data.items;
      state.paginationListMID = {
        page: action.payload?.page || 1,
        pagesize: action.payload?.pagesize || 10,
        total: action.payload?.total || 0,
      };
      state.error = "";
      state.loadingSearchMID = false;
    },

    // LFG GENERATE RESULT TABLE
    [generateLfgResultTable.pending.toString()]: (state) => {
      state.loadingLfgResultTable = true;
    },
    [generateLfgResultTable.rejected.toString()]: (state, action) => {
      state.loadingLfgResultTable = false;
      state.error = action.payload;
    },
    [generateLfgResultTable.fulfilled.toString()]: (state, action) => {
      state.lfgResultTableData = action.payload;
      state.error = "";
      state.loadingLfgResultTable = false;
    },

    // VI PHOTO
    [searchVIPhoto.pending.toString()]: (state) => {
      state.loadingVIPhoto = true;
    },
    [searchVIPhoto.rejected.toString()]: (state, action) => {
      state.loadingVIPhoto = false;
      state.error = action.payload;
    },
    [searchVIPhoto.fulfilled.toString()]: (state, action) => {
      state.listVIPhoto = action.payload.data.items;
      state.paginationVIPhotoList = {
        page: action.payload?.page || 1,
        pagesize: action.payload?.pagesize || 10,
        total: action.payload?.total || 0,
      };
      state.error = "";
      state.loadingVIPhoto = false;
    },

    [getVISiteName.pending.toString()]: (state) => {
      state.loadingVIPhoto = true;
      state.loadingGenerateVIComment = true;
    },
    [getVISiteName.rejected.toString()]: (state, action) => {
      state.loadingVIPhoto = false;
      state.loadingGenerateVIComment = false;
      state.error = action.payload;
    },
    [getVISiteName.fulfilled.toString()]: (state, action) => {
      state.listSiteNamesVI = action.payload;
      state.error = "";
      state.loadingVIPhoto = false;
      state.loadingGenerateVIComment = false;
    },

    // VI COMMENT
    [searchVIComment.pending.toString()]: (state) => {
      state.loadingVIComment = true;
    },
    [searchVIComment.rejected.toString()]: (state, action) => {
      state.loadingVIComment = false;
      state.error = action.payload;
    },
    [searchVIComment.fulfilled.toString()]: (state, action) => {
      state.listVIComment = action.payload.data.items;
      state.paginationVICommentList = {
        page: action.payload?.page || 1,
        pagesize: action.payload?.pagesize || 10,
        total: action.payload?.total || 0,
      };
      state.error = "";
      state.loadingVIComment = false;
    },
  },
});

export const {
  resetPerPage,
  setClearSearch,
  setSiteNameSearch,
  setStartDateSearch,
  setEndDateSearch,
  setJobNumberSearch,
  setWellIDSearch,
  setOperatorSearch,
  setWellListSelected,
  setLoading,
  setIsSearch,
  setRememberSearchWillList,

  // Well Raw data
  setClearDataRawData,

  // Well Photo
  setWPClearSearch,
  setSiteNameSearchWP,
  setEndDateSearchWP,
  setStartDateSearchWP,
  setJobNumberSearchWP,
  setWellIDSearchWP,
  setOperatorSearchWP,
  setLoadingWPList,
  setLoadingExportWP,
  setDataSelectWPs,
  setLoadingWellGasData,
  setLoadingRawData,
  setIsSearchWP,
  setRememberSearchWP,

  // Well Manual Import
  setUploadBySearchMID,
  setUploadDateSearchMID,
  setClearSearchMID,
  setLoadingSearchMID,
  setDataFileUpload,
  setAllowOverwriteData,
  setClearDataFileUpload,
  setIsSearchMID,
  setRememberSearchMID,

  setLoadingLfgResultTable,

  // VI photo
  setLoadingVIPhoto,
  setListVIPhoto,
  setDataSelectVIPhotos,
  setSiteNameSearchVIPhoto,
  setDateSearchVIPhoto,
  setTypeObservationSearchVIPhoto,
  setIsSearchVIPhoto,
  setVIPhotoClearSearch,
  setLoadingExportVIPhoto,

  // VI Comment
  setLoadingVIComment,
  setListVIComment,
  setVICommentsSelected,
  setDataSelectVIComments,
  setSiteNameSearchVIComment,
  setDateSearchVIComment,
  setTypeObservationSearchVIComment,
  setNoteSearchVIComment,
  setIsSearchVIComment,
  setVICommentClearSearch,
  setRememberSearchVIComment,

  // Generate VI Comment
  setLoadingGenerateVIComment,
  setSelectedGenerateVIComment,

} = landFillGas.actions;

const { reducer: landFillGasStore } = landFillGas;
export default landFillGasStore;
