export const listObjective = [
  {
    label: "Additional investigation",
    value: "Additional investigation",
  },
  {
    label: "Investigation",
    value: "Investigation",
  },
  {
    label: "Compliance monitoring",
    value: "Compliance monitoring",
  },
  {
    label: "Emergency response",
    value: "Emergency response",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const listSampleType = [
  {
    label: "Groundwater",
    value: "Groundwater",
  },
  {
    label: "Surface Water",
    value: "Surface Water",
  },
  {
    label: "Potable Water",
    value: "Potable Water",
  },
  {
    label: "Trade Waste",
    value: "Trade Waste",
  },
  {
    label: "Effluent",
    value: "Effluent",
  },
  {
    label: "Leachate",
    value: "Leachate",
  },
  {
    label: "Saline",
    value: "Saline",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const listContainerType = [
  {
    label: "Plastic 1L",
    value: "Plastic 1L",
  },
  {
    label: "Plastic 500 mL",
    value: "Plastic 500 mL",
  },
  {
    label: "Plastic 100 mL",
    value: "Plastic 100 mL",
  },
  {
    label: "Plastic 50 mL",
    value: "Plastic 50 mL",
  },
  {
    label: "Glass 250 mL",
    value: "Glass 250 mL",
  },
  {
    label: "Glass 40 mL (x2)",
    value: "Glass 40 mL (x2)",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const listDrinkingWater = [
  {
    label: "NZ Drinking Water Standards 2022. MAVs",
    value: "Drinking water_NZ Drinking Water Standards 2022. MAVs",
  },
  {
    label: "NZ Aesthetic Values for Drinking Water 2022",
    value: "Drinking water_NZ Aesthetic Values for Drinking Water 2022",
  },
  {
    label: "WHO 2022 Guidelines for Drinking-water Quality",
    value: "Drinking water_WHO 2022 Guidelines for Drinking-water Quality",
  },
  {
    label: "USEPA April 2023 RSLs – Resident tapwater ",
    value: "Drinking water_USEPA April 2023 RSLs – Resident tapwater",
  },
  {
    label: "ADWG v3.8 updated September 2022. Health-based guideline values",
    value:
      "Drinking water_ADWG v3.8 updated September 2022. Health-based guideline values",
  },
  {
    label: "ADWG v3.8 updated September 2022. Aesthetic guideline values",
    value:
      "Drinking water_ADWG v3.8 updated September 2022. Aesthetic guideline values",
  },
  {
    label: "CLWRP Region-wide water quality limits for groundwater",
    value:
      "Drinking water_CLWRP Region-wide water quality limits for groundwater",
  },
];
export const listRecreational = [
  {
    label: "NHMRC 2019. Recreational guideline values for PFAS",
    value: "Recreational_NHMRC 2019. Recreational guideline values for PFAS",
  },
  {
    label: "ADWG v3.8 update September 2022. Health values x10",
    value: "Recreational_ADWG v3.8 update September 2022. Health values x10",
  },
  {
    label: "NZ Drinking Water Standards 2022. MAVs x10",
    value: "Recreational_NZ Drinking Water Standards 2022. MAVs x10",
  },
];
export const listSelectLandUse = [
  {
    value: "Vapour intrusion_CRC_HSL-A/B_",
    label: "HSL-A/B (low and high density residential)",
  },
  {
    value: "Vapour intrusion_CRC_HSL-C_",
    label: "HSL-C (recreational)",
  },
  {
    value: "Vapour intrusion_CRC_HSL-D_",
    label: " HSL-D (commercial/industrial)",
  },
];
export const listSelectLandUseCRC = [
  {
    label: "Residential/agricultural",
    value: "Vapour intrusion_MfE 1999_Residential/agricultural_",
  },
  {
    label: "Commercial/industrial",
    value: "Vapour intrusion_MfE 1999_Commercial/industrial_",
  },
];
export const listSelectIrrgation = [
  {
    label: "ANZECC 2000, Chapter 4.2. Irrigation long term trigger values",
    value:
      "Irrigation_ANZECC 2000, Chapter 4.2. Irrigation long term trigger values",
  },
  {
    label: "ANZECC 2000, Chapter 4.2. Irrigation short term trigger values",
    value:
      "Irrigation_ANZECC 2000, Chapter 4.2. Irrigation short term trigger values",
  },
];
export const listSelectLivestockWater = [
  {
    label: "ANZECC 2000, Chapter 4.3. Livestock drinking water",
    value: "Livestock water_ANZECC 2000, Chapter 4.3. Livestock drinking water",
  },
];

export const listSelectBuildingAndStructures = [
  {
    label: "AS2159 Table 6.4.2(C) Concrete piles",
    value: "Buildings and structures_AS2159 Table 6.4.2(C) Concrete piles",
  },
];

export const TYPE_CRITERIA = {
  AQUATIC_ECOSYSTEMS: "Aquatic ecosystems",
  HUMAN_HEALTH: "Human health",
  IRRIGATION: "Irrigation",
  LIVESTOCK_WATER: "Livestock water",
  BUILDING_AND_STRUCTURES: "Buildings and structures",
};

export const listCriteria = [
  {
    label: TYPE_CRITERIA.AQUATIC_ECOSYSTEMS,
    value: TYPE_CRITERIA.AQUATIC_ECOSYSTEMS,
  },
  {
    label: TYPE_CRITERIA.HUMAN_HEALTH,
    value: TYPE_CRITERIA.HUMAN_HEALTH,
  },
  {
    label: TYPE_CRITERIA.IRRIGATION,
    value: TYPE_CRITERIA.IRRIGATION,
  },
  {
    label: TYPE_CRITERIA.LIVESTOCK_WATER,
    value: TYPE_CRITERIA.LIVESTOCK_WATER,
  },
  {
    label: TYPE_CRITERIA.BUILDING_AND_STRUCTURES,
    value: TYPE_CRITERIA.BUILDING_AND_STRUCTURES,
  },
];

export const listTypeFileUpload = [
  {
    label: "Hill",
    value: ".xml",
  },
  {
    label: "Analytica",
    value: ".xlsx",
  },
  {
    label: "Eurofins",
    value: ".csv",
  },
];

export const listLevelProtection = [
  {
    label: "99% level of protection",
    value: "ANZG_Freshwater_99% level of protection",
  },
  {
    label: "95% level of protection",
    value: "ANZG_Freshwater_95% level of protection",
  },
  {
    label: "90% level of protection",
    value: "ANZG_Freshwater_90% level of protection",
  },
  {
    label: "80% level of protection",
    value: "ANZG_Freshwater_80% level of protection",
  },
];

export const listLevelProtectionMarine = [
  {
    label: "99% level of protection",
    value: "ANZG_Marine_99% level of protection",
  },
  {
    label: "95% level of protection",
    value: "ANZG_Marine_95% level of protection",
  },
  {
    label: "90% level of protection",
    value: "ANZG_Marine_90% level of protection",
  },
  {
    label: "80% level of protection",
    value: "ANZG_Marine_80% level of protection",
  },
];

export const listLevelProtectionHickey = [
  {
    label: "99% level of protection",
    value: "Hickey, 2013. Freshwater grading values_99% level of protection",
  },
  {
    label: "95% level of protection",
    value: "Hickey, 2013. Freshwater grading values_95% level of protection",
  },
  {
    label: "90% level of protection",
    value: "Hickey, 2013. Freshwater grading values_90% level of protection",
  },
  {
    label: "80% level of protection",
    value: "Hickey, 2013. Freshwater grading values_80% level of protection",
  },
];

export const typeSearch = {
  PROJECT_NUMBER_SEARCH: "PROJECT_NUMBER_SEARCH",
  SAMPLE_SEARCH: "SAMPLE_SEARCH",
  COLLECTION_DATA_SEARCH: "COLLECTION_DATA_SEARCH",
  OBJECTIVE_SEARCH: "OBJECT_SEARCH",
  SAMPLE_TYPE: "SAMPLE_TYPE",
  SAMPLE_NAME: "SAMPLE_NAME",
  COC_ID: "COC_ID",
  SITE_ID: "SITE_ID",
  SITE_ADDRESS: "SITE_ADDRESS",
  PARENT_NAME: "PARENT_NAME",
  COLLECTION_DATE_START: "COLLECTION_DATE_START",
  COLLECTION_DATE_END: "COLLECTION_DATE_END",
};
export const listColorStying = [
  {
    label: "Black circle",
    value: "Black circle",
  },
  {
    label: "Black square",
    value: "Black square",
  },
  {
    label: "Black triangle",
    value: "Black triangle",
  },
  {
    label: "Bold",
    value: "Bold",
  },
  {
    label: "Dashed outlined",
    value: "Dashed outlined",
  },
  {
    label: "Green outlined",
    value: "Green outlined",
  },
  {
    label: "Grey shaded",
    value: "Grey shaded",
  },
  {
    label: "Italicised",
    value: "Italicised",
  },
  {
    label: "Red",
    value: "Red",
  },
  {
    label: "Underlined",
    value: "Underlined",
  },
  {
    label: "Filled pink",
    value: "Filled pink",
  },
  {
    label: "Filled blue",
    value: "Filled blue",
  },
  {
    label: "Filled green",
    value: "Filled green",
  },
  {
    label: "Filled orange",
    value: "Filled orange",
  },
  {
    label: "Filled yellow",
    value: "Filled yellow",
  },
];

export const CODE_CRITERIA = {
  AQE: "Aquatic ecosystems",
  HUH: "Human health",
  IRN: "Irrigation",
  LIR: "Livestock water",
  BAS: "Buildings and structures",
};
export const listLandUseCRC = [
  {
    label: "Select",
    value: "Select",
  },
  {
    label: "HSL-A/B (low and high density residential)",
    value: "HSL-A/B (low and high density residential)",
  },
  {
    label: "HSL-C (recreational)",
    value: "HSL-C (recreational)",
  },
  {
    label: "HSL-D (commercial/industrial)",
    value: "HSL-D (commercial/industrial)",
  },
];
export const listGroundWaterDepthCRC = [
  {
    label: "2 to <4m",
    value: "2 to <4m",
  },
  {
    label: "4 to <8m",
    value: "4 to <8m",
  },
  {
    label: "8m+",
    value: "8m+",
  },
];
export const listSoilTypeCCRC = [
  {
    label: "Sand",
    value: "Sand",
  },
  {
    label: "Silt",
    value: "Silt",
  },
  {
    label: "Clay",
    value: "Clay",
  },
];
export const listSoilTypeMfe = [
  {
    label: "Sand",
    value: "Sand",
  },
  {
    label: "Sandy silt",
    value: "Sandy silt",
  },
  {
    label: "Silty clay",
    value: "Silty clay",
  },
  {
    label: "Clay",
    value: "Clay",
  },
  {
    label: "Pumice",
    value: "Pumice",
  },
  {
    label: "Peats and Highly Organic Soils",
    value: "Peats and Highly Organic Soils",
  },
  {
    label: "Fractured basalts",
    value: "Fractured basalts",
  },
  {
    label: "Gravels",
    value: "Gravels",
  },
];
export const listLandUseMfE = [
  {
    label: "Residential/agricultural",
    value: "Residential/agricultural",
  },
  {
    label: "Commercial/industrial",
    value: "commercial/industrial",
  },
];
export const listGroundWaterDepthMfE = [
  {
    label: "2m",
    value: "2m",
  },
  {
    label: "4m",
    value: "4m",
  },
  {
    label: "8m",
    value: "8m",
  },
];
export const listExposureScenario = [
  {
    label: "Indoor",
    value: "indoor",
  },
  {
    label: "Outdoor",
    value: "outdoor",
  },
];
