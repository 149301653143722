import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import endpoints from "../service/endpoints";
import { fieldObservationActionType } from "./actionTypes";
import API from "service/api";

/**
 * Function handle action get list fieldObservations
 */
export const getLists = createAsyncThunk(
  `fieldObservations/${fieldObservationActionType.GET_LIST}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints?.fieldObservations?.LIST,
        method: "POST",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

export const getListsExportPDF = createAsyncThunk(
  `fieldObservations/${fieldObservationActionType.GET_LIST_PDF}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints?.fieldObservations?.LIST_PDF,
        method: "POST",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/** @type {object} init state of pagination */
export const pagination = {
  page: 1,
  pageSize: 10,
  total: 0,
};

/** @type {object} declare type sample */
type Sample = {
  [key: string]: any;
};

/** @type {object} declare type fieldObservations */
export const fieldObservationsList: Sample[] = [];

/** @type {object} init state of fieldObservations */
const initialState = {
  listLoading: false,
  dataTabs: [],
  fieldObservations: fieldObservationsList,
  fieldObservationsExportPDF: fieldObservationsList,
  error: "",
  pagination: pagination,
};

const fieldObservationsSlice = createSlice({
  name: "fieldObservations",
  initialState,
  reducers: {
    clearErrorMessage: (state) => {
      state.error = "";
    },

    resetPagination: (state) => {
      state.pagination = pagination;
    },
  },
  extraReducers: {
    [getLists.pending.toString()]: (state) => {
      state.listLoading = true;
    },
    [getLists.rejected.toString()]: (state, action) => {
      state.listLoading = false;
      state.error = action.payload;
    },
    [getLists.fulfilled.toString()]: (state, action) => {
      state.fieldObservations = action.payload?.data?.items || [];
      state.pagination = {
        page: action.payload?.page || 1,
        pageSize: action.payload?.pagesize || 10,
        total: action.payload?.total || 0,
      };
      state.listLoading = false;
      state.error = "";
    },
    [getListsExportPDF.pending.toString()]: (state) => {
      state.listLoading = true;
    },
    [getListsExportPDF.rejected.toString()]: (state, action) => {
      state.listLoading = false;
      state.error = action.payload;
    },
    [getListsExportPDF.fulfilled.toString()]: (state, action) => {
      state.fieldObservationsExportPDF = action.payload?.data || [];
      state.listLoading = false;
      state.error = "";
    },
  },
});

export const { clearErrorMessage, resetPagination } =
  fieldObservationsSlice.actions;

const { reducer: fieldObservations } = fieldObservationsSlice;
export default fieldObservations;
