export const authActionType = {
  LOGIN: "LOGIN",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  REMEMBER_ME: "REMEMBER_ME",
  SET_FORGOT_PASSWORD_DATA: "SET_FORGOT_PASSWORD_DATA",
  LOGOUT: "LOGOUT",
  CLEAR_ERROR_MESSAGE: "CLEAR_ERROR_MESSAGE",
  FIRST_TIME_RESET_PASSWORD: "FIRST_TIME_RESET_PASSWORD",
  SET_FIRST_TIME_RESET_PASSWORD_DATA: "SET_FIRST_TIME_RESET_PASSWORD_DATA",
  GET_BY_ID: "GET_BY_ID",
  GET_MY_ACCOUNT: "GET_MY_ACCOUNT",
  ERROR_MY_ACCOUNT: "ERROR_MY_ACCOUNT",
  SEND_EMAIL_FORGOT_PASSWORD: "SEND_EMAIL_FORGOT_PASSWORD",
  CHANGE_PASSWORD_FORGOT_PASSWORD: "CHANGE_PASSWORD_FORGOT_PASSWORD",
  UPDATE_MY_ACCOUNT: "UPDATE_MY_ACCOUNT",
  CHANGE_PASSWORD_MY_ACCOUNT: "CHANGE_PASSWORD_MY_ACCOUNT",
};

export const appActionType = {
  SET_LOADING: "SET_LOADING",
};

export const userActionType = {
  GET_LIST: "GET_LIST",
  GET_BY_ID: "GET_BY_ID",
  ADD_USER: "ADD_USER",
  ADD_USER_SUCCESS: "ADD_USER_SUCCESS",
  ERROR_ADD_USER: "ERROR_ADD_USER",
};

export const sampleActionType = {
  GET_LIST: "GET_LIST",
  GET_LIST_NAME: "GET_LIST_NAME",
  GET_LIST_GENERATED: "GET_LIST_GENERATED",
  GET_BY_ID: "GET_BY_ID",
  GET_SAMPLE: "GET_SAMPLE",
  ADD_SAMPLE: "ADD_SAMPLE",
  SEARCH: "SEARCH",
  SEARCH_SAMPLES: "SEARCH_SAMPLES",
  EDIT_SAMPLE: "EDIT_SAMPLE",
  ASSIGN_TEST_TYPE: "ASSIGN_TEST_TYPE",
};

export const fieldObservationActionType = {
  GET_LIST: "GET_LIST",
  GET_LIST_PDF: "GET_LIST_PDF",
};

export const COCActionType = {
  GET_LIST: "GET_LIST",
  GET_BY_ID: "GET_BY_ID",
  GET_COC: "GET_COC",
  ADD_COC: "ADD_COC",
  ADD_COC_SUCCESS: "ADD_COC_SUCCESS",
  ERROR_ADD_COC: "ERROR_ADD_COC",
  EDIT_COC: "EDIT_COC",
  EDIT_COC_SUCCESS: "EDIT_COC_SUCCESS",
  ERROR: "ERROR",
  SEARCH: "SEARCH",
  UPLOAD_FILE: "UPLOAD_FILE",
  UPLOAD_FILE_S3: "UPLOAD_FILE_S3",
  REMOVE_FILE: "REMOVE_FILE",
  REMOVE_ALL_FILE: "REMOVE_ALL_FILE",
  GET_LIST_FILES: "GET_LIST_FILES",
  GET_FILE: "GET_FILE",
  GENERATE_REPORT: "GENERATE_REPORT",
  GET_LIST_SAMPLES_FILE: "GET_LIST_SAMPLES_FILE",
  GET_FILE_DETAIL: "GET_FILE_DETAIL",
  MAPPING_SAMPLE: "MAPPING_SAMPLE",
  ACCEPT_MAPPING: "ACCEPT_MAPPING",
  REJECT_MAPPING: "REJECT_MAPPING",
  GET_LIST_PHOTO: "GET_LIST_PHOTO",
  GET_REPORT_PARAMETER: "GET_REPORT_PARAMETER",
  GET_MAPPING_SAMPLE: "GET_MAPPING_SAMPLE",
  PRINTED_COC: "PRINTED_COC",
};

export const TestTypeActionType = {
  GET_LIST: "GET_LIST",
};

export const CodeActionType = {
  GET_LIST: "GET_LIST",
  GET_LIST_ADDITIONAL: "GET_LIST_ADDITIONAL",
};

export const ParameterActionType = {
  GET_LIST: "GET_LIST",
  GET_LIST_OF_REGION: "GET_LIST_OF_REGION",
};

export const FileActionType = {
  GET_LIST: "GET_LIST",
  GET_FILE: "GET_FILE",
  GET_FILE_DETAIL: "GET_FILE_DETAIL",
  GET_FILE_SAMPLES: "GET_FILE_SAMPLES",
  UPLOAD_FILE: "UPLOAD_FILE",
  UPLOAD_FILE_S3: "UPLOAD_FILE_S3",
  REMOVE_FILE: "REMOVE_FILE",
  REMOVE_ALL_FILE: "REMOVE_ALL_FILE",
  GENERATE_REPORT: "GENERATE_REPORT",
  GET_REPORT_PARAMETER: "GET_REPORT_PARAMETER",
  GET_REPORT_PARAMETER_TIME_OUT_COC: "GET_REPORT_PARAMETER_TIME_OUT_COC",
  GET_REPORT_PARAMETER_TIME_OUT: "GET_REPORT_PARAMETER_TIME_OUT",
  GET_FORM_LAB_FILE: "GET_FORM_LAB_FILE",
  SAVE_FORM_LAB_FILE: "SAVE_FORM_LAB_FILE",
};

export const PhotoActionType = {
  GET_LIST: "GET_LIST",
  SEARCH_PHOTO: "SEARCH_PHOTO",
  GET_PHOTO_DETAIL: "GET_PHOTO_DETAIL",
  EXPORT_PHOTO: "EXPORT_PHOTO",
};

export const NotificationActionType = {
  GET_LIST: "GET_LIST",
  UPDATE_NOTIFICATION: "UPDATE_NOTIFICATION",
  MARK_NOTIFICATION: "MARK_NOTIFICATION",
};

export const GroundWaterLevel = {
  GET_LIST: "GET_LIST",
  GET_LIST_PROJECT_NUMBER: "GET_LIST_PROJECT_NUMBER",
  GET_LIST_BOREHOLE_ID: "GET_LIST_BOREHOLE_ID",
  GET_LIST_LEVEL_LOGGER: "GET_LIST_LEVEL_LOGGER",
  UPLOAD_LEVEL_LOGGER: "UPLOAD_LEVEL_LOGGER",
  GET_PROJECT_NUMBER_CORE_GS: "GET_PROJECT_NUMBER_CORE_GS",
  GET_BOREHOLE_ID_CORE_GS: "GET_BOREHOLE_ID_CORE_GS",
  CREATE_NEW_SCHEDULE_FIELD: "CREATE_NEW_SCHEDULE_FIELD",
  GET_FIELD_DATA_SHEET: "GET_FIELD_DATA_SHEET",
  GET_FIELD_DATA_SHEET_GRAPH: "GET_FIELD_DATA_SHEET_GRAPH",
  GET_DATA_LEVEL_LOGGER_HEADER: "GET_DATA_LEVEL_LOGGER_HEADER",
  GET_DATA_LEVEL_LOGGER_DETAIL: "GET_DATA_LEVEL_LOGGER_DETAIL",
  DELETE_LEVEL_LOGGER_DATA: "DELETE_LEVEL_LOGGER_DATA",
  GET_DATA_MASTER_TABLE: "GET_DATA_MASTER_TABLE",
  GET_DATA_MASTER_TABLE_TIME_OUT: "GET_DATA_MASTER_TABLE_TIME_OUT",
  EXPORT_XLSX_DATA_MASTER_TABLE: "EXPORT_XLSX_DATA_MASTER_TABLE",
  GET_VISIT_DATA: "GET_VISIT_DATA",
  GET_RAINFALL_LIST: "GET_RAINFALL_LIST",
  GET_DATA_RAIN_FALL_HEADER: "GET_DATA_RAIN_FALL_HEADER",
  GET_DATA_RAIN_FALL_DETAIL: "GET_DATA_RAIN_FALL_DETAIL",
  GET_RESULT_TABLE_LIST: "GET_RESULT_TABLE_LIST",
  APPROVE_RESULT_TABLE_DATA: "APPROVE_RESULT_TABLE_DATA",
  UNAPPROVED_RESULT_TABLE_DATA: "UNAPPROVED_RESULT_TABLE_DATA",
  GROUP_REVIEWER_DATA: "GROUP_REVIEWER_DATA",
  APPROVE_MASTER_TABLE_DATA: "APPROVE_MASTER_TABLE_DATA",
  UNAPPROVE_MASTER_TABLE_DATA: "UNAPPROVE_MASTER_TABLE_DATA",
  GET_MASTER_TABLE_REVIEWER_DATA: "GET_MASTER_TABLE_REVIEWER_DATA",
  GET_DATA_ATTRIBUTES: "GET_DATA_ATTRIBUTES",
};

export const WaterQuality = {
  GET_LIST: "GET_LIST",
  GET_DETIAL_WATER_QUALITY: "GET_DETIAL_WATER_QUALITY",
  UPDATE_DETIAL_WATER_QUALITY: "UPDATE_DETIAL_WATER_QUALITY",
  GET_LIST_ASSIGN_TEST: "GET_LIST_ASSIGN_TEST",
  GET_LIST_COMPANY: "GET_LIST_COMPANY",
  GET_LIST_LAB: "GET_LIST_LAB",
  GET_COC: "GET_COC",
  GET_LIST_COC: "GET_LIST_COC",
  DOWNLOAD_PHOTO: "DOWNLOAD_PHOTO",
  GET_DATA_EXPORT_GW: "GET_DATA_EXPORT_GW",
  GET_DATA_EXPORT_SW: "GET_DATA_EXPORT_SW",
  UPLOAD_COC: "UPLOAD_COC",
  GET_DATA_RESULT_TABLE_COC: "GET_DATA_RESULT_TABLE_COC",
  GET_DATA_RESULT_TABLE_WITHOUT_COC: "GET_DATA_RESULT_TABLE_WITHOUT_COC",
  GET_COC_LIST_WQ: "GET_COC_LIST_WQ",
  GET_DATA_FIELD_SHEET_PARAMETERS: "GET_DATA_FIELD_SHEET_PARAMETERS",
  GET_DATA_CRITERIA: "GET_DATA_CRITERIA",
  REPORT_PARAMETER: "REPORT_PARAMETER",
  GET_REPORT_PARAMETER: "GET_REPORT_PARAMETER",
  REPORT_PARAMETER_WITHOUT_COC: "REPORT_PARAMETER_WITHOUT_COC",
  GET_LAB_FORM: "GET_LAB_FORM",
};

export const ManualDip = {
  GET_LIST: "GET_LIST",
  GET_LIST_BOREHOLE_ID: "GET_LIST_BOREHOLE_ID",
  GET_LIST_PROJECT_NUMBER: "GET_LIST_PROJECT_NUMBER",
  GET_FIELD_DATA_SHEET: "GET_FIELD_DATA_SHEET",
  GET_FIELD_DATA_SHEET_GRAPH: "GET_FIELD_DATA_SHEET_GRAPH",
  GET_BOREHOLE_ID_CORE_GS: "GET_BOREHOLE_ID_CORE_GS",
  GET_PROJECT_NUMBER_CORE_GS: "GET_PROJECT_NUMBER_CORE_GS",
  GET_VISIT_DATA: "GET_VISIT_DATA",
  GET_MASTER_TABLE_REVIEWER_DATA: "GET_MASTER_TABLE_REVIEWER_DATA",
  GROUP_REVIEWER_DATA: "GROUP_REVIEWER_DATA",
  GET_RESULT_TABLE_LIST: "GET_RESULT_TABLE_LIST",
  GET_DETAIL_SUMMARY: "GET_DETAIL_SUMMARY",
  GET_DATA_ATTRIBUTES: "GET_DATA_ATTRIBUTES",
};

export const MapActionType = {
  GET_LOCATION_LIST: "GET_LOCATION_LIST",
  GET_LOCATION_DETAIL: "GET_LOCATION_DETAIL",
};

export const LandFillGas = {
  GET_WELL_IDS: "GET_WELL_IDS",
  GET_WELL_SITE_NAMES: "GET_WELL_SITE_NAMES",
  SEARCH_WELL_LIST: "SEARCH_WELL_LIST",
  GET_WELL_LIST_DETAIL: "GET_WELL_LIST_DETAIL",

  WELL_DATA_GET_RAW_DATA: "WELL_DATA_GET_RAW_DATA",
  WELL_DATA_UPDATE_WELL_GAS_DATA: 'WELL_DATA_UPDATE_WELL_GAS_DATA',

  WELL_DATA_GET_ATTRIBUTE_DATA: "WELL_DATA_GET_ATTRIBUTE_DATA",
  WELL_DATA_UPDATE_ATTRIBUTE_DATA: 'WELL_DATA_UPDATE_ATTRIBUTE_DATA',


  WELL_DATA_DELETE: "WELL_DATA_DELETE",
  WELL_DATA_GET_SURVEY_DATA: "WELL_DATA_GET_SURVEY_DATA",
  WELL_DATA_UPDATE_SURVEY_DATA: "WELL_DATA_UPDATE_SURVEY_DATA",

  WELL_MANUAL_IMPORT: "WELL_MANUAL_IMPORT",
  WELL_MANUAL_SEARCH: "WELL_MANUAL_SEARCH",
  WELL_MANUAL_DELETE: "WELL_MANUAL_DELETE",
  WELL_MANUAL_DOWNLOAD: "WELL_MANUAL_DOWNLOAD",

  SEARCH_WELL_PHOTO: "SEARCH_WELL_PHOTO",
  DOWNLOAD_WELL_PHOTO: "DOWNLOAD_WELL_PHOTO",
  EXPORT_WELL_PHOTO: "EXPORT_WELL_PHOTO",
  EXPORT_WELL_PHOTO_PDF: "EXPORT_WELL_PHOTO_PDF",

  UPDATE_WELL_LIST: "UPDATE_WELL_LIST",
  EXPORT_WELL_LIST: "EXPORT_WELL_LIST",

  SEARCH_VI_NOTES: "SEARCH_VI_NOTES",
  EXPORT_VI_NOTES: "EXPORT_VI_NOTES",

  SEARCH_PHOTO_NOTES: "SEARCH_PHOTO_NOTES",
  EXPORT_PHOTO_NOTES: "EXPORT_PHOTO_NOTES",

  WELL_GENERATE_RESULT_TABLE: "WELL_GENERATE_RESULT_TABLE",
  WELL_EXPORT_EXCEL_RESULT_TABLE: "WELL_EXPORT_EXCEL_RESULT_TABLE",

  SEARCH_VI_PHOTO: "SEARCH_VI_PHOTO",
  DOWNLOAD_VI_PHOTO: "DOWNLOAD_VI_PHOTO",
  EXPORT_VI_PHOTO: "VI_PHOTO_EXPORT",
  EXPORT_VI_PHOTO_PDF: "VI_PHOTO_EXPORT_PDF",

  SEARCH_VI_COMMENT: "SEARCH_VI_COMMENT",
  
  GET_SITENAMES_VI_COMMENT: "GET_SITENAMES_VI_COMMENT",
}
