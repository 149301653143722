import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  CODE_CRITERIA,
  listContainerType,
  listObjective,
  listSampleType,
} from "constants/listDataWaterQuality";
import { find, get } from "lodash";
import API from "service/api";
import endpoints from "../service/endpoints";
import { WaterQuality } from "./actionTypes";

/**
 * Function get list ground water level
 */
export const getListWaterQuality = createAsyncThunk(
  `gwl/fieldsheet/${WaterQuality.GET_LIST}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.waterQuantity.GET_WATER_QUALITY,
        method: "POST",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get list ground water level
 */
export const getDetailWaterQuality = createAsyncThunk(
  `gwl/fieldsheet/${WaterQuality.GET_DETIAL_WATER_QUALITY}`,
  async (idDetailData: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url:
          endpoints.waterQuantity.GET_DETAIL_WATER_QUALITY + `/${idDetailData}`,
        method: "GET",
        header: "content-type: application/json",
      });
      const dataDetailWaterQuality = {
        ...response.data,
        objective: find(listObjective, { value: response.data.objective }),
        sample_type: find(listSampleType, { value: response.data.sample_type }),
        sample_container_type: find(listContainerType, {
          value: response.data.sample_container_type,
        }),
        collection_date_time: new Date(response.data.collection_date_time),
      };
      return dataDetailWaterQuality;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get list ground water level
 */
export const getListAssgnTest = createAsyncThunk(
  `gwl/fieldsheet/${WaterQuality.GET_LIST_ASSIGN_TEST}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.waterQuantity.GET_ASSIGN_TEST,
        method: "GET",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get list ground water level
 */
export const getListCompany = createAsyncThunk(
  `gwl/fieldsheet/${WaterQuality.GET_LIST_COMPANY}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.waterQuantity.GET_LIST_COMPANY,
        method: "GET",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get list ground water level
 */
export const getListLab = createAsyncThunk(
  `gwl/fieldsheet/${WaterQuality.GET_LIST_LAB}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.waterQuantity.GET_LIST_LAB,
        method: "GET",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get list ground water level
 */
export const getCOCDetail = createAsyncThunk(
  `gwl/fieldsheet/${WaterQuality.GET_COC}`,
  async (id: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.waterQuantity.GET_COC + `/${id}`,
        method: "GET",
        header: "content-type: application/json",
      });
      localStorage.setItem(
        "selectedRowsWaterQuality",
        JSON.stringify(response.data.samples)
      );
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get list ground water level
 */
export const getListCOC = createAsyncThunk(
  `gwl/fieldsheet/${WaterQuality.GET_LIST_COC}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.waterQuantity.GET_COC_LIST,
        method: "POST",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/**
 * Function get list ground water level
 */
export const getPhotoListWQ = createAsyncThunk(
  `gwl/fieldsheet/${WaterQuality.GET_COC_LIST_WQ}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.waterQuantity.GET_COC_LIST_WQ,
        method: "POST",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

export const getDataExportGW = createAsyncThunk(
  `gwl/fieldsheet/${WaterQuality.GET_DATA_EXPORT_GW}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.waterQuantity.GET_DATA_EXPORT_GW + `/${data}`,
        method: "GET",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

export const getDataExportSW = createAsyncThunk(
  `gwl/fieldsheet/${WaterQuality.GET_DATA_EXPORT_SW}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.waterQuantity.GET_DATA_EXPORT_SW,
        method: "POST",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

export const getDataFileCOC = createAsyncThunk(
  `gwl/fieldsheet/${WaterQuality.GET_DATA_RESULT_TABLE_COC}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.waterQuantity.GET_DATA_RESULT_TABLE_COC + `/${data}`,
        method: "GET",
        header: "content-type: application/json",
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);
export const getDataFileNonCOC = createAsyncThunk(
  `gwl/fieldsheet/${WaterQuality.GET_DATA_RESULT_TABLE_WITHOUT_COC}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.waterQuantity.GET_DATA_RESULT_TABLE_WITHOUT_COC,
        method: "GET",
        header: "content-type: application/json",
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);
export const getDataFieldSheetParameters = createAsyncThunk(
  `gwl/fieldsheet/${WaterQuality.GET_DATA_FIELD_SHEET_PARAMETERS}`,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.waterQuantity.GET_DATA_FIELD_SHEET_PARAMETERS,
        method: "POST",
        header: "content-type: application/json",
        data,
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

export const getDataCriteria = createAsyncThunk(
  `gwl/fieldsheet/${WaterQuality.GET_DATA_CRITERIA}`,
  async (dataBody: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.waterQuantity.GET_DATA_CRITERIA,
        method: "get",
        header: "content-type: application/json",
      });

      if (response.status === 200) {
        let newListFile: any = [];
        newListFile = response.data.map((data: any, index) => ({
          ...data,
          criteria_name: data.name,
          component: dataBody.listComponents[index],
          body:
            get(
              find(dataBody.generateDataCriteria.criterias, {
                criteria: data.wq_business_criteria_id,
              }),
              "body"
            ) || {},
          checked: find(dataBody.generateDataCriteria.criterias, {
            criteria: data.wq_business_criteria_id,
          })
            ? true
            : false,
        }));
        return newListFile;
      }
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

export const getGenerateReportParameter = createAsyncThunk(
  `gwl/fieldsheet/${WaterQuality.REPORT_PARAMETER}`,
  async (idCoc: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.waterQuantity.REPORT_PARAMETER + `/${idCoc}`,
        method: "GET",
        header: "content-type: application/json",
      });
      let newListCriteria = [];
      if (response.data.criterias && response.data.criterias.length > 0) {
        newListCriteria = response.data.criterias.map((data) => {
          if (data.criteria_name === CODE_CRITERIA.HUH) {
            const dataCRC = get(data, ["body", "cRC"]);
            const dataMFE = get(data, ["body", "mFE"]);
            let dataCRCLanUse = [];
            let dataMfeLanUse = [];
            if (
              get(dataCRC, ["landUse"]) &&
              get(dataCRC, ["landUse"]).length > 0
            ) {
              dataCRCLanUse = (get(dataCRC, ["landUse"]) || []).map((crc) => ({
                ...crc,
                category_code: crc.category_code
                  .replace(get(dataCRC, "groundwaterDepth"), "")
                  .replace(`_${get(dataCRC, "soilType")}`, ""),
              }));
            }

            if (
              get(dataMFE, ["landUse"]) &&
              get(dataMFE, ["landUse"]).length > 0
            ) {
              dataMfeLanUse = (get(dataMFE, ["landUse"]) || []).map((crc) => ({
                ...crc,
                category_code: crc.category_code
                  .replace(get(dataMFE, "groundwaterDepth"), "")
                  .replace(`_${get(dataMFE, "soilType")}`, "")
                  .replace(`_${get(dataMFE, "secnario")}`, ""),
              }));
            }
            return (data = {
              ...data,
              body: {
                ...data.body,
                cRC: { ...dataCRC, landUse: dataCRCLanUse },
                mFE: { ...dataMFE, landUse: dataMfeLanUse },
              },
            });
          }
          return data;
        });
      }
      const responseData = {
        ...response.data,
        criterias: newListCriteria,
      };
      return responseData;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

export const getGenerateReportParameterWhithouCOC = createAsyncThunk(
  `gwl/fieldsheet/${WaterQuality.REPORT_PARAMETER_WITHOUT_COC}`,
  async (idCoc: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.waterQuantity.REPORT_PARAMETER_WITHOUT_COC + `/${idCoc}`,
        method: "GET",
        header: "content-type: application/json",
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

export const getLabForm = createAsyncThunk(
  `gwl/fieldsheet/${WaterQuality.GET_LAB_FORM}`,
  async (idFile: any, { rejectWithValue }) => {
    try {
      const response = await API({
        url: endpoints.waterQuantity.GET_LAB_FORM + `/${idFile}`,
        method: "GET",
        header: "content-type: application/json",
      });
      return response.data;
    } catch (error: any) {
      let errorMessage = "Internal Server Error";
      if (error?.data?.code) {
        errorMessage = error.data.code;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

/** @type {object} init state of pagination */
export const pagination = {
  page: 1,
  pagesize: 10,
  // pagesize: 10,
  total: 0,
};

export const dataAquaticDefault = {
  freshWater: [],
  marine: [],
  hickey: [],
  nZ: [],
};
export const humanHealthDefault = {
  drinkWater: [],
  recreational: [],
  landUseVapour: [],
  groundwaterDepthVapour: "",
  soilTypeVapour: "",
  landUseMfE: [],
  groundwaterDepthMfE: "",
  soilTypeMfE: "",
  exposureScenario: "",
};

/** @type {object} init state of parameter */
export const initialDataWaterQuality = {
  wq_survey_sample_container_id: undefined,
  sampler: undefined,
  project_number: undefined,
  site_address: undefined,
  objective: undefined,
  collection_date_time: undefined,
  sample_location: undefined,
  sample_name: undefined,
  sample_type: undefined,
  qc_sample_type: undefined,
  parent_sample: undefined,
  sample_container_type: undefined,
  sample_barcodes: undefined,
  test_id_list: undefined,
  test_string_list: undefined,
};

/** @type {object} init state of cocs */
const initialState = {
  loading: false,
  listWaterQuality: [],
  paginationWaterQuality: pagination,
  projectNumberSearch: undefined,
  samplerSearch: undefined,
  collectionDataSearch: undefined,
  objectiveSearch: undefined,
  sampleTypeSearch: undefined,
  error: "",
  detailWaterQuality: initialDataWaterQuality,
  listAssignTest: [],
  waterQualitySelected: [],
  isUpdateDataDetail: false,
  detailWaterQualityInit: initialDataWaterQuality,
  waterQualityGenerateCOC: [],
  listCompany: [],
  listLab: [],
  cocDetail: {},
  loadingCOC: false,
  listCOC: [],
  paginationCOC: pagination,
  projectSearchCOC: undefined,
  cocIdSearch: undefined,
  objectSearchCOC: undefined,
  siteIDSearchCOC: undefined,
  siteAddressesSearchCOC: undefined,
  loadingPhotoList: false,
  listPhoto: [],
  paginationPhotoList: pagination,
  projectSearchPL: undefined,
  objectSearchPL: undefined,
  sampleSearchPL: undefined,
  parentSearchPL: undefined,
  collectionStartDateSearchPL: undefined,
  collectionEndDateSearchPL: undefined,
  dataSelectPhotos: [],
  dataExportGW: undefined,
  dataExportGWHeader: undefined,
  dataExportGWParameters: [],
  dataExportGWQcSamples: [],
  dataExportSW: undefined,
  dataExportSWHeader: undefined,
  dataExportSWDetails: [],
  loadingResultTable: false,
  dataFileResult: [],
  loadingResultTableNonCOC: false,
  dataFileResultNonCOC: [],
  loadingFieldSheetParameters: false,
  dataFieldSheetParamters: [],
  paginationFieldSheetParameters: pagination,
  dataFieldSheetParameterSelected: [],
  loadingCritetia: false,
  listCriteria: [],
  generateDataCriteria: undefined,
  generateDataWithoutCOC: undefined,
  dataAquatic: dataAquaticDefault,
  humanHealth: humanHealthDefault,
  irrigation: [],
  liveStocks: [],
  buildingAndStructure: [],
  labForm: {},
  loadingLabForm: false,
  isSearch: false,
  loadingExportPhotos: false,
};

const waterQuality = createSlice({
  name: "coc",
  initialState,
  reducers: {
    resetPerPage: (state) => {
      state.paginationWaterQuality = {
        ...state.paginationWaterQuality,
        pagesize: 10,
      };
    },
    setClearSearch: (state, action) => {
      state.projectNumberSearch = undefined;
      state.samplerSearch = undefined;
      state.collectionDataSearch = undefined;
      state.objectiveSearch = undefined;
      state.sampleTypeSearch = undefined;
      state.projectSearchCOC = undefined;
      state.cocIdSearch = undefined;
      state.objectSearchCOC = undefined;
      state.siteIDSearchCOC = undefined;
      state.siteAddressesSearchCOC = undefined;
      state.projectSearchPL = undefined;
      state.objectSearchPL = undefined;
      state.sampleSearchPL = undefined;
      state.parentSearchPL = undefined;
      state.collectionStartDateSearchPL = undefined;
      state.collectionEndDateSearchPL = undefined;
      state.isSearch = false;
    },
    setClearSearchAddSample: (state, action) => {
      state.samplerSearch = undefined;
      state.collectionDataSearch = undefined;
      state.sampleTypeSearch = undefined;
      state.projectSearchCOC = undefined;
      state.cocIdSearch = undefined;
      state.objectSearchCOC = undefined;
      state.siteIDSearchCOC = undefined;
      state.siteAddressesSearchCOC = undefined;
      state.objectiveSearch = undefined;
      state.isSearch = false;
    },
    setDataSearchProject: (state, action) => {
      state.projectNumberSearch = action.payload;
    },
    setDataSampleSearch: (state, action) => {
      state.samplerSearch = action.payload;
    },
    setCollectionDataSearch: (state, action) => {
      state.collectionDataSearch = action.payload;
    },
    setObjectiveSearch: (state, action) => {
      state.objectiveSearch = action.payload;
    },
    setDataSampleType: (state, action) => {
      state.sampleTypeSearch = action.payload;
    },
    setDetailWaterQualityInit: (state, action) => {
      state.detailWaterQuality = action.payload;
      state.detailWaterQualityInit = action.payload;
    },
    setDetailWaterQuality: (state, action) => {
      state.detailWaterQuality = action.payload;
    },
    setSelectWaterQuality: (state, action) => {
      state.waterQualitySelected = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsUpdateDataDetail: (state, action) => {
      state.isUpdateDataDetail = action.payload;
    },
    setWaterQualityGenerateCOC: (state, action) => {
      state.waterQualityGenerateCOC = action.payload;
    },
    setCOCDetail: (state, action) => {
      state.cocDetail = action.payload;
    },
    setProjectCOCSearch: (state, action) => {
      state.projectSearchCOC = action.payload;
    },
    setCOCIDSearch: (state, action) => {
      state.cocIdSearch = action.payload;
    },
    setObjectSearchCOC: (state, action) => {
      state.objectSearchCOC = action.payload;
    },
    setSiteIDSearchCOC: (state, action) => {
      state.siteIDSearchCOC = action.payload;
    },
    setSiteAddressSearchCOC: (state, action) => {
      state.siteAddressesSearchCOC = action.payload;
    },
    setProjectSearchPL: (state, action) => {
      state.projectSearchPL = action.payload;
    },
    setObjectSearchPL: (state, action) => {
      state.objectSearchPL = action.payload;
    },
    setSampleSearchPL: (state, action) => {
      state.sampleSearchPL = action.payload;
    },
    setParentSearchPL: (state, action) => {
      state.parentSearchPL = action.payload;
    },
    setCollectionStartDateSearchPL: (state, action) => {
      state.collectionStartDateSearchPL = action.payload;
    },
    setCollectionEndDateSearchPL: (state, action) => {
      state.collectionEndDateSearchPL = action.payload;
    },
    setLoadingPhotoList: (state, action) => {
      state.loadingPhotoList = action.payload;
    },
    setDataSelectPhotos: (state, action) => {
      state.dataSelectPhotos = action.payload;
    },
    setClearDataExport: (state, action) => {
      state.dataExportGW = undefined;
      state.dataExportGWHeader = undefined;
      state.dataExportGWParameters = [];
      state.dataExportGWQcSamples = [];
      state.dataExportSW = undefined;
      state.dataExportSWHeader = undefined;
      state.dataExportSWDetails = [];
    },
    setLoadingResultTable: (state, action) => {
      state.loadingResultTable = action.payload;
    },
    setLoadingResultTableNonCOC: (state, action) => {
      state.loadingResultTableNonCOC = action.payload;
    },
    setDataFieldSheetParemetersSelected: (state, action) => {
      state.dataFieldSheetParameterSelected = action.payload;
    },
    setLoadingFieldSheetParameters: (state, action) => {
      state.loadingFieldSheetParameters = action.payload;
    },
    setDataAquatic: (state, action) => {
      state.dataAquatic = action.payload;
    },
    setHumanHealth: (state, action) => {
      state.humanHealth = action.payload;
    },

    setIrrigation: (state, action) => {
      state.irrigation = action.payload;
    },
    setLiveStocks: (state, action) => {
      state.liveStocks = action.payload;
    },
    setBuildingAndStructure: (state, action) => {
      state.buildingAndStructure = action.payload;
    },
    setClearDataCriteria: (state, action) => {
      state.dataAquatic = dataAquaticDefault;
      state.humanHealth = humanHealthDefault;
      state.irrigation = [];
      state.liveStocks = [];
      state.buildingAndStructure = [];
    },
    setListCriteria: (state, action) => {
      state.listCriteria = action.payload;
    },
    setGenerateDataWithoutCOC: (state, action) => {
      state.generateDataWithoutCOC = action.payload;
    },
    setIsSearch: (state, action) => {
      state.isSearch = action.payload;
    },
    setLabForm: (state, action) => {
      state.labForm = action.payload;
    },
    setDataGenerateDataCriteria: (state, action) => {
      state.generateDataCriteria = action.payload;
      state.listCriteria = [];
    },
    setClearPaginationCoc: (state, action) => {
      state.paginationCOC = {
        page: action.payload?.page || 1,
        pagesize: action.payload?.pagesize || 10,
        total: action.payload?.total || 0,
      };
    },
    setLoadingExportPhotos: (state, action) => {
      state.loadingExportPhotos = action.payload;
    },
  },
  extraReducers: {
    [getListWaterQuality.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getListWaterQuality.rejected.toString()]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getListWaterQuality.fulfilled.toString()]: (state, action) => {
      state.listWaterQuality = action.payload.data.items;
      state.paginationWaterQuality = {
        page: action.payload?.page || 1,
        pagesize: action.payload?.pagesize || 10,
        total: action.payload?.total || 0,
      };
      state.error = "";
      state.loading = false;
    },
    [getDetailWaterQuality.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getDetailWaterQuality.rejected.toString()]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getDetailWaterQuality.fulfilled.toString()]: (state, action) => {
      state.detailWaterQuality = action.payload;
      state.detailWaterQualityInit = action.payload;
      state.loading = false;
    },
    [getListAssgnTest.rejected.toString()]: (state, action) => {
      state.error = action.payload;
    },
    [getListAssgnTest.fulfilled.toString()]: (state, action) => {
      state.listAssignTest = action.payload;
    },
    [getListCompany.fulfilled.toString()]: (state, action) => {
      state.listCompany = action.payload;
    },
    [getListLab.fulfilled.toString()]: (state, action) => {
      state.listLab = action.payload;
    },
    [getCOCDetail.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getCOCDetail.rejected.toString()]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getCOCDetail.fulfilled.toString()]: (state, action) => {
      state.waterQualityGenerateCOC = action.payload.samples;
      state.cocDetail = action.payload;
      state.loading = false;
    },
    [getListCOC.pending.toString()]: (state) => {
      state.loadingCOC = true;
    },
    [getListCOC.rejected.toString()]: (state, action) => {
      state.loadingCOC = false;
      state.error = action.payload;
    },
    [getListCOC.fulfilled.toString()]: (state, action) => {
      state.listCOC = action.payload.data.items;
      state.loadingCOC = false;
      state.paginationCOC = {
        page: action.payload?.page || 1,
        pagesize: action.payload?.pagesize || 10,
        total: action.payload?.total || 0,
      };
    },
    [getPhotoListWQ.pending.toString()]: (state) => {
      state.loadingPhotoList = true;
    },
    [getPhotoListWQ.rejected.toString()]: (state, action) => {
      state.loadingPhotoList = false;
      state.error = action.payload;
    },
    [getPhotoListWQ.fulfilled.toString()]: (state, action) => {
      state.listPhoto = action.payload.data.items;
      state.loadingPhotoList = false;
      state.paginationPhotoList = {
        page: action.payload?.page || 1,
        pagesize: action.payload?.pagesize || 10,
        total: action.payload?.total || 0,
      };
    },
    [getDataExportGW.pending.toString()]: (state) => {
      state.loadingFieldSheetParameters = true;
    },
    [getDataExportGW.rejected.toString()]: (state, action) => {
      state.loadingFieldSheetParameters = false;
      state.error = action.payload;
    },
    [getDataExportGW.fulfilled.toString()]: (state, action) => {
      state.dataExportGW = action.payload;
      state.dataExportGWHeader = action.payload.header;
      state.dataExportGWParameters = action.payload.parameters;
      state.dataExportGWQcSamples = action.payload.qc_samples;
    },
    [getDataExportSW.pending.toString()]: (state) => {
      state.loadingFieldSheetParameters = true;
    },
    [getDataExportSW.rejected.toString()]: (state, action) => {
      state.loadingFieldSheetParameters = false;
      state.error = action.payload;
    },
    [getDataExportSW.fulfilled.toString()]: (state, action) => {
      state.dataExportSW = action.payload;
      state.dataExportSWHeader = action.payload.header;
      state.dataExportSWDetails = action.payload.details;
    },
    [getDataFileCOC.pending.toString()]: (state) => {
      state.loadingResultTable = true;
    },
    [getDataFileCOC.rejected.toString()]: (state, action) => {
      state.loadingResultTable = false;
      state.error = action.payload;
    },
    [getDataFileCOC.fulfilled.toString()]: (state, action) => {
      state.loadingResultTable = false;
      state.dataFileResult = action.payload;
    },
    [getDataFileNonCOC.pending.toString()]: (state) => {
      state.loadingResultTableNonCOC = true;
    },
    [getDataFileNonCOC.rejected.toString()]: (state, action) => {
      state.loadingResultTableNonCOC = false;
      state.error = action.payload;
    },
    [getDataFileNonCOC.fulfilled.toString()]: (state, action) => {
      state.loadingResultTableNonCOC = false;
      state.dataFileResultNonCOC = action.payload;
    },
    [getDataFieldSheetParameters.pending.toString()]: (state) => {
      state.loadingFieldSheetParameters = true;
    },
    [getDataFieldSheetParameters.rejected.toString()]: (state, action) => {
      state.loadingFieldSheetParameters = false;
      state.error = action.payload;
    },
    [getDataFieldSheetParameters.fulfilled.toString()]: (state, action) => {
      state.loadingFieldSheetParameters = false;
      state.dataFieldSheetParamters = action.payload.data.items;
      state.paginationFieldSheetParameters = {
        page: action.payload?.page || 1,
        pagesize: action.payload?.pagesize || 10,
        total: action.payload?.total || 0,
      };
    },
    [getDataCriteria.pending.toString()]: (state) => {
      state.loadingCritetia = true;
    },
    [getDataCriteria.rejected.toString()]: (state, action) => {
      state.loadingCritetia = false;
      state.error = action.payload;
    },
    [getDataCriteria.fulfilled.toString()]: (state, action) => {
      state.loadingCritetia = false;
      state.listCriteria = action.payload;
    },
    [getGenerateReportParameter.pending.toString()]: (state) => {
      state.loadingCritetia = true;
    },
    [getGenerateReportParameter.rejected.toString()]: (state, action) => {
      state.loadingCritetia = false;
      state.error = action.payload;
    },
    [getGenerateReportParameter.fulfilled.toString()]: (state, action) => {
      state.loadingCritetia = false;
      state.generateDataCriteria = action.payload;
    },
    [getGenerateReportParameterWhithouCOC.pending.toString()]: (state) => {
      state.loadingResultTableNonCOC = true;
    },
    [getGenerateReportParameterWhithouCOC.rejected.toString()]: (
      state,
      action
    ) => {
      state.loadingResultTableNonCOC = false;
      state.error = action.payload;
    },
    [getGenerateReportParameterWhithouCOC.fulfilled.toString()]: (
      state,
      action
    ) => {
      state.loadingResultTableNonCOC = false;
      state.generateDataWithoutCOC = action.payload;
    },
    [getLabForm.pending.toString()]: (state) => {
      state.loadingLabForm = true;
    },
    [getLabForm.rejected.toString()]: (state, action) => {
      state.loadingLabForm = false;
      state.error = action.payload;
    },
    [getLabForm.fulfilled.toString()]: (state, action) => {
      state.loadingLabForm = false;
      state.labForm = action.payload;
    },
  },
});

export const {
  resetPerPage,
  setClearSearch,
  setDataSearchProject,
  setDataSampleSearch,
  setCollectionDataSearch,
  setObjectiveSearch,
  setDataSampleType,
  setDetailWaterQuality,
  setSelectWaterQuality,
  setLoading,
  setIsUpdateDataDetail,
  setDetailWaterQualityInit,
  setWaterQualityGenerateCOC,
  setCOCDetail,
  setProjectCOCSearch,
  setCOCIDSearch,
  setObjectSearchCOC,
  setSiteIDSearchCOC,
  setSiteAddressSearchCOC,
  setClearSearchAddSample,
  setProjectSearchPL,
  setObjectSearchPL,
  setSampleSearchPL,
  setParentSearchPL,
  setCollectionStartDateSearchPL,
  setCollectionEndDateSearchPL,
  setLoadingPhotoList,
  setDataSelectPhotos,
  setClearDataExport,
  setLoadingResultTable,
  setLoadingResultTableNonCOC,
  setDataFieldSheetParemetersSelected,
  setLoadingFieldSheetParameters,
  setDataAquatic,
  setHumanHealth,
  setIrrigation,
  setLiveStocks,
  setBuildingAndStructure,
  setClearDataCriteria,
  setListCriteria,
  setGenerateDataWithoutCOC,
  setIsSearch,
  setLabForm,
  setDataGenerateDataCriteria,
  setClearPaginationCoc,
  setLoadingExportPhotos,
} = waterQuality.actions;

const { reducer: waterQualityStore } = waterQuality;
export default waterQualityStore;
