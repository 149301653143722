import { listSideBar } from "constants/listSideBar";
import { useAppSelector } from "hooks";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./style.scss";

/**
 * Component to display navigation in sidebar
 */
export const NavigationBarNew = () => {
  const currentRoute = useAppSelector((state) => state.app.currentRoute);
  const [listSideBarUI, setListSideBarUI] = useState<any[]>([]);

  useEffect(() => {
      const newListSideBarUI = JSON.parse(localStorage.getItem("listSideBar") || "[]");
    
      if (newListSideBarUI && currentRoute !== "/") {
        setListSideBarUI(newListSideBarUI);
        
        const activateRoute = (indices) => {
          const temp = JSON.parse(JSON.stringify(listSideBar));
          let current = temp;
          
          indices.forEach(index => {
            current[index].isActive = true;
            if (current[index].isHasChildren) {
              current[index].isOpen = true;
              current = current[index].listChildren;
            }            
          });
          saveSideBar(temp);
        };
    
        //handle special routes from welcome page
        if (currentRoute === "/level-logger") {
          activateRoute([2, 0, 0]); 
        } else if (currentRoute === "/water-quality") {
          activateRoute([1, 0]);
        } else if (currentRoute === "/sample-scheduling") {
          activateRoute([0, 0]);
        } else if (currentRoute === "/well-data") {
          activateRoute([3, 0, 0]);
        }
      } else {
        setListSideBarUI(listSideBar);
      }
    // eslint-disable-next-line
  }, []);

  /*
  * Set all nodes to inactive
  */
  const resetSideBar = (newListSideBarUI = null) => {
    console.log("resetSideBar")
    let tempSidebar;
    if (newListSideBarUI) {
      tempSidebar = newListSideBarUI;
    } else {
      tempSidebar = listSideBarUI;
    }
    console.log(tempSidebar)
    let resetListSideBar = tempSidebar.map((sideBar) => ({
      ...sideBar,
      isActive: false,
      listChildren: sideBar.listChildren.map((sideBarChild) => ({
        ...sideBarChild,
        isActive: false,
        listChildren: sideBarChild.listChildren.map((grandChild) => ({
          ...grandChild,
          isActive: false,
        })),
      })),
    }));
    return resetListSideBar;
  };

  /*
  * Toggle open state of sidebar
  */
  const toggleOpenState = (item, condition) => {
    return condition ? { ...item, isOpen: !item.isOpen } : { ...item };
  };

  /*
  * Save sidebar state to local storage
  */
  const saveSideBar = (newListSideBar) => {
    setListSideBarUI(newListSideBar);
    localStorage.setItem("listSideBar", JSON.stringify(newListSideBar));
  };

  /*
  * Handle level 1 click
  * Open/close level 2 sub menu
  */
  const handleClickParent = (sideBarUpdate) => {
    let newListSideBar = listSideBarUI.map((sideBar) => 
      toggleOpenState(sideBar, sideBarUpdate.nameParent === sideBar.nameParent)
    );
    saveSideBar(newListSideBar);
  };

  /*
  * Handle level 2 click
  * Open/close level 3 sub menu
  */
  const handleClickChildwithChildren = (sideBarUpdate, sideBarChildUpdate) => {
    console.log("handleClickChildwithChildren");
    
    let newListSideBar = listSideBarUI.map((sideBar) => {
      if (sideBarUpdate.nameParent === sideBar.nameParent) {
        let newListChildSideBar = sideBar.listChildren.map((sideBarChild) => 
          toggleOpenState(sideBarChild, sideBarChild.link === sideBarChildUpdate.link)
        );

        return { ...sideBar, listChildren: newListChildSideBar };
      }
      return { ...sideBar };
    });
    saveSideBar(newListSideBar);
  };

  /*
  * Handle level 2 leaf node click
  * Set leaf and parent node to active
  * Set all other nodes to inactive and closed
  */
  const handleClickChildNoChildren = (sideBarUpdate, sideBarChildUpdate) => {
    console.log("handleClickChildNoChildren")
    console.log(sideBarUpdate);
    console.log(sideBarChildUpdate);
    let inactiveSideBar = resetSideBar();
    let newListSideBar = inactiveSideBar.map((sideBar) => {
      if (sideBarUpdate.nameParent === sideBar.nameParent) {
        let newListChildSideBar = sideBar.listChildren.map((sideBarChild) => {
          if (sideBarChild.link === sideBarChildUpdate.link) {
            //set child to active
            return { ...sideBarChild, isActive: true };
          }
          //close sibling nodes
          return { ...sideBarChild, isOpen: false };
        });
        //set parent to active
        return {
          ...sideBar,
          listChildren: newListChildSideBar,
          isActive: true,
        };
      }
      //close all nodes in other parents
      let newListChildSideBar = sideBar.listChildren.map((sideBarChild) => {
        return { ...sideBarChild, isOpen: false };
      });
      return { ...sideBar, listChildren: newListChildSideBar, isOpen: false};
    });
    saveSideBar(newListSideBar);
  };

  /*
  * Handle level 3 click
  * Set grandchild, child and parent node to active
  * Set all other nodes to inactive and closed
  */
  const handleClickGrandChild = (
    sideBarUpdate,
    sideBarChildUpdate,
    sideBarGrandchildren
  ) => {
    let inactiveSideBar = resetSideBar();
    let newListSideBar = inactiveSideBar.map((sideBar) => {
      if (sideBarUpdate.nameParent === sideBar.nameParent) {
        let newListChildSideBar = sideBar.listChildren.map((sideBarChild) => {
          if (sideBarChild.link === sideBarChildUpdate.link) {
            let newListGrandChildSideBar = sideBarChild.listChildren.map(
              (grandChild) => {
                if (grandChild.link === sideBarGrandchildren.link) {
                  //set grandchild to active
                  return { ...grandChild, isActive: true };
                }
                //close sibling nodes
                return { ...grandChild, isOpen: false };
              }
            );
            //set child to active
            return { ...sideBarChild, isActive: true, listChildren: newListGrandChildSideBar };
          }

          //close sibling nodes
          let newListChildrenBarChild = sideBarChild.listChildren.map(
            (dataChildrenBarChild) => ({ ...dataChildrenBarChild, isOpen: false, })
          );
          return { ...sideBarChild, isOpen: false, listChildren: newListChildrenBarChild };
        });
        //set parent to active
        return { ...sideBar, isActive: true, listChildren: newListChildSideBar };
      }

      //close all nodes in other parents
      let newListChildSideBar = sideBar.listChildren.map((sideBarChild) => {
        return { ...sideBarChild, isOpen: false };
      });
      return { ...sideBar, listChildren: newListChildSideBar, isOpen: false};
    });
    saveSideBar(newListSideBar);
  };

  return (
    <>
      {listSideBarUI.map((sideBar) =>
        sideBar.isHasChildren ? (
          <div className="sibar-level-weka" key={sideBar.nameParent}>
            <li
              className={`sidebar-item have-submenu ${
                sideBar.isActive ? "active" : ""
              }`}
            >
              <div
                className={`sidebar-link dropdown-toggle level-one ${
                  sideBar.isActive ? "sidebar-weka-active" : ""
                } ${sideBar.isOpen ? "open" : ""}`}
                onClick={() => handleClickParent(sideBar)}
              >
                <span>{sideBar.nameParent}</span>
              </div>
              <ul className={`submenu ${sideBar.isOpen ? "show-menu" : ""}`}>
                {sideBar.listChildren.map((sideBarChild) =>
                  sideBarChild.isHasChildren ? (
                    <div className="sibar-level-weka" key={sideBarChild.name}>
                      <li
                        className={`sidebar-item have-submenu ${
                          sideBarChild.isActive ? "active" : ""
                        }`}
                      >
                        <div
                          className={`sidebar-link dropdown-toggle level-two ${
                            sideBarChild.isActive ? "sidebar-weka-active" : ""
                          } ${sideBarChild.isOpen ? "open" : ""}`}
                          onClick={() =>
                            handleClickChildwithChildren(sideBar, sideBarChild)
                          }
                        >
                          <span>{sideBarChild.name}</span>
                        </div>
                        <ul
                          className={`submenu ${
                            sideBarChild.isOpen ? "show-menu" : ""
                          }`}
                        >
                          {sideBarChild.listChildren.map(
                            (sideBarGrandchildren) => (
                              <li
                                key={sideBarGrandchildren.name}
                                className={`sidebar-item ${
                                  sideBarGrandchildren.isActive ? "active" : ""
                                }`}
                                onClick={() =>
                                  handleClickGrandChild(
                                    sideBar,
                                    sideBarChild,
                                    sideBarGrandchildren
                                  )
                                }
                              >
                                <Link
                                  className="sidebar-link level-three"
                                  to={sideBarGrandchildren.link}
                                >
                                  {sideBarGrandchildren.name}
                                </Link>
                              </li>
                            )
                          )}
                        </ul>
                      </li>
                    </div>
                  ) : (
                    <li
                      onClick={() =>
                        handleClickChildNoChildren(sideBar, sideBarChild)
                      }
                      className={`sidebar-item ${
                        sideBarChild.isActive ? "active" : ""
                      }`}
                      key={sideBarChild.name}
                    >
                      <Link className="sidebar-link level-two" to={sideBarChild.link}>
                        {sideBarChild.name}
                      </Link>
                    </li>
                  )
                )}
              </ul>
            </li>
          </div>
        ) : (
          <li
            className={`sidebar-item ${sideBar.isActive ? "active" : ""}`}
            key={sideBar.nameParent}
          >
            <Link className="sidebar-link level-one" to={sideBar.link}>
              {sideBar.nameParent}
            </Link>
          </li>
        )
      )}
    </>
  );
};
