import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NavDropdown } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import LoadingOverlay from "react-loading-overlay-ts";

import { useAppDispatch, useAppSelector } from "hooks";
import { logout } from "store/auth";
/* Todo */
import Notification from "components/Notification";
import logo from "assets/images/logo.png";
import "./style.css";
import { setCurrentRoute } from "store/app";
import { NavigationBarNew } from "components/NavigationBar/NavigationBarNew";

/**
 * Layout for User
 * @param {object} props
 */
function UserLayout(props) {
  const dispatch = useAppDispatch();
  const [isLoadingSave, isPrint, isLoading] = useAppSelector((state) => [
    state.coc.isLoadingSave,
    state.coc.isPrint,
    state.app.isLoading,
  ]);

  const [isShowMenu, setIsShowMenu] = useState(true);
  const { children } = props;

  useEffect(() => {
    const data = localStorage.getItem("CURRENT_ROUTE");
    if (data !== null) dispatch(setCurrentRoute(JSON.parse(data)));
    // eslint-disable-next-line
  }, []);

  /**
   * Function handle action toggle sidebar menu
   */
  const toggleMenu = () => {
    setIsShowMenu(!isShowMenu);
  };

  const currentUser = JSON.parse(localStorage.getItem("user") || "{}");

  /**
   * Function handle action logout
   */
  const onLogout = () => {
    dispatch(logout());
    localStorage.removeItem("listSideBar");
  };

  return (
    <LoadingOverlay active={isLoading} spinner>
      <div className="wrapper">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        {isShowMenu && (
          <nav
            id="sidebar"
            className={`sidebar js-sidebar ${isShowMenu ? "" : "collapsed"}`}
          >
            <div
              className="sidebar-blur"
              style={{ display: isLoadingSave || isPrint ? "block" : "none" }}
            />
            <div className="sidebar-content js-simplebar">
              <Link className="sidebar-brand" to="/">
                <img src={logo} alt="logo" className="sidebar-logo"/>
              </Link>
              <ul className="sidebar-nav">
                <NavigationBarNew />
              </ul>
            </div>
          </nav>
        )}

        <div
          className={`main ${isShowMenu ? "" : "menu-collapsed"}`}
        >
          <nav className="navbar navbar-expand navbar-light navbar-bg">
            <span
              className="sidebar-toggle js-sidebar-toggle"
              onClick={() => toggleMenu()}
            >
              <i className="hamburger align-self-center"></i>
            </span>

            <div className="navbar-collapse collapse">
              <ul className="navbar-nav navbar-align">
                <li className="nav-item dropdown item-notification">
                  <Notification />
                </li>
                <li className="nav-item dropdown">
                  <NavDropdown
                    id="nav-dropdown-dark-example"
                    title={
                      <b className="username">
                        <i className="fa-solid fa-circle-user fa-2xl avatar"></i>
                      </b>
                    }
                  >
                    <NavDropdown.Item className="user-text">{currentUser?.username || ""}</NavDropdown.Item>
                    <NavDropdown.Item className="logout-text" onClick={() => onLogout()}>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </li>
              </ul>
            </div>
          </nav>
          <hr />
          <main className="content">
            <div className="container-fluid">{children}</div>
          </main>
          <hr />
          <footer className="footer">                
                <div>
                  <p className="copyright">
                      Copyright &copy; 2024 WEKA. All rights reserved.
                  </p>
                </div>
                <div>
                  <ul className="links">
                    <li>
                      <a href="https://tonkintaylor.sharepoint.com/sites/connect/OurKnowledge/Pages/Digital_hub/Weka-app.aspx" target="_blank" rel="noopener noreferrer">
                        Support
                      </a>
                    </li>
                    <li>
                      <a href="https://www.tonkintaylor.co.nz/terms-of-use/" target="_blank" rel="noopener noreferrer">
                        Terms of Use
                      </a>
                    </li>
                    <li>
                      <a href="https://www.tonkintaylor.co.nz/tt-privacy-statement/" target="_blank" rel="noopener noreferrer">
                        Privacy Statement
                      </a>
                    </li>
                  </ul>
                </div>
          </footer>
        </div>
      </div>
    </LoadingOverlay>
  );
}

export default UserLayout;
