import { formatDateTime } from "helpers/convertDateTime";
import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect, useState } from "react";
import { Form, NavDropdown } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import PATHS from "routes/const";
import { setCurrentRoute } from "store/app";
import { getLists, markNotification } from "store/notification";
import "./style.css";

type Object = {
  [key: string]: any;
};

/**
 * Component to display popup notification
 */
export default function Notification() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { notifications } = useAppSelector((state) => state.notification);
  const [notificationFilter, setNotificationFilter] =
    useState<string>("unread");
  const [listMessage, setListMessage] = useState<Object[]>([]);
  const currentRoute = localStorage.getItem("currentRoute") || "homepage";

  /**
   * handle when click notifi
   * @async
   * @param {Object} data
   */
  const handleClickNotifi = async (data) => {
    if (!data?.isread) {
      await dispatch(markNotification(data?.ttcl_notificationid || "0"));
    }

    if (data?.cocid) {
      if (currentRoute !== PATHS.RESULT_TABLE_COC || id !== data?.cocid) {
        const path = `${PATHS.RESULT_TABLE_COC}/${data?.cocid}`;
        history.push(path);
        dispatch(setCurrentRoute(path));
      }
      dispatch(getLists());
    } else if (data?.fileid) {
      if (currentRoute !== PATHS.RESULT_TABLE_NO_COC) {
        history.push(PATHS.RESULT_TABLE_NO_COC);
        dispatch(setCurrentRoute(PATHS.RESULT_TABLE_NO_COC));
      }
      dispatch(getLists());
    }
  };

  useEffect(() => {
    if (notificationFilter === "unread") {
      const listNotifications = notifications?.filter(
        (it: Object) => it?.isread === false
      );
      setListMessage(listNotifications);
    } else {
      setListMessage(notifications);
    }
  }, [notificationFilter, notifications]);

  useEffect(() => {
    dispatch(getLists());
    // eslint-disable-next-line
  }, []);

  let notifCount = notifications?.filter((it: Object) => it?.isread === false)?.length || 0;
  let largeNotifCount = notifCount > 99;

  return (
    <NavDropdown
      id="nav-dropdown-notification"
      className="nav-notification"
      title={
        <span
          className="nav-icon"
          id="alertsDropdown"
          data-bs-toggle="dropdown"
        >
          <div className="position-relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-bell align-middle"
            >
              <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
              <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
            </svg>
            <span className="indicator">
              <p className={`text-indicator ${largeNotifCount ? 'text-xsm' : ''}`}>
                {notifCount}
              </p>
            </span>
          </div>
        </span>
      }
    >
      <div className="dropdown-title">{notificationFilter === "unread"
    ? `You have ${listMessage.length} unread notifications`
    : `You have ${listMessage.length} notifications`}</div>
      <div className="notification-filter">
        <div
          className={`tab ${notificationFilter === "unread" ? "active unread" : ""}`}
          onClick={() => setNotificationFilter("unread")}
        >
          Unread
          <Form.Check
            inline
            label=""
            name="notification"
            type="radio"
            checked={notificationFilter === "unread"}
            id="inline-radio-unread"
            onChange={() => setNotificationFilter("unread")}
            style={{ display: 'none' }}
          />
        </div>
        <div
          className={`tab ${notificationFilter === "all" ? "active all" : ""}`}
          onClick={() => setNotificationFilter("all")}
        >
          All
          <Form.Check
            inline
            label=""
            name="notification"
            type="radio"
            id="inline-radio-all"
            checked={notificationFilter === "all"}
            onChange={() => setNotificationFilter("all")}
            style={{ display: 'none' }}
          />
        </div>
        <span className="glider"></span>
      </div>

      <div className="notification-content">
        {listMessage?.length > 0 ? (
          listMessage?.map((item: Object) => (
            <NavDropdown.Item
              key={item?.ttcl_notificationid}
              onClick={() => handleClickNotifi(item)}
            >
              <span className="notification-message">
                {item?.message || ""}
              </span>
              <span className="notification-datetime">
                {formatDateTime(item?.lastmodified)}
              </span>
            </NavDropdown.Item>
          ))
        ) : (
          <p className="dropdown-title pb-sm-0">You don't have any notifications</p>
        )}
      </div>
    </NavDropdown>
  );
}
