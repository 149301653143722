import moment from "moment";

export const formatDateTime = (data: string) => {
  if (!data || data.length === 0) return data;
  if (data) return moment(String(data)).format("MM/DD/YYYY HH:mm:ss");
};

export const formatDateTimeDDMMYYYY = (data: string) => {
  if (!data || data.length === 0) return data;
  if (data) return moment(String(data)).format("DD/MM/YYYY HH:mm:ss");
};
